/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    industry: [],
    actorsOptions: [],
    actorsLocations: [],
    cveVendors: [],
    cveProducts: [],
    technology: [],
    category: [],
    ttpsStages: [],
    malwareNames: [],
    malwareOptions: [],
    qualysOS: [],
    sourceNames: [],
};

export default (state = initialState, action) => {
    if (action.type === 'LOAD_COMMON_DATA') {
        return action.payload;
    }

    if (action.type === 'LOAD_COMMON_DATA_INDUSTRY') {
        return { ...state, industry: action.payload.industry };
    }

    if (action.type === 'LOAD_COMMON_DATA_ACTORS') {
        return { ...state, actorsOptions: action.payload.actorsOptions };
    }

    if (action.type === 'LOAD_COMMON_DATA_LOCATIONS') {
        return { ...state, actorsLocations: action.payload.actorsLocations };
    }

    if (action.type === 'LOAD_COMMON_DATA_TECHNOLOGY') {
        return { ...state, technology: action.payload.technology };
    }

    if (action.type === 'LOAD_COMMON_DATA_CATEGORY') {
        return { ...state, category: action.payload.category };
    }

    if (action.type === 'LOAD_COMMON_DATA_VENDORS') {
        return { ...state, cveVendors: action.payload.cveVendors };
    }

    if (action.type === 'LOAD_COMMON_DATA_PRODUCTS') {
        return { ...state, cveProducts: action.payload.cveProducts };
    }

    if (action.type === 'LOAD_COMMON_DATA_TTPS_STAGES') {
        return { ...state, ttpsStages: action.payload.ttpsStages };
    }

    if (action.type === 'LOAD_COMMON_DATA_MALWARE_NAMES') {
        return { ...state, malwareNames: action.payload.malwareNames };
    }

    if (action.type === 'LOAD_COMMON_DATA_MALWARE_OPTIONS') {
        return { ...state, malwareOptions: action.payload.malwareOptions };
    }

    if (action.type === 'LOAD_COMMON_DATA_QUALYS') {
        return { ...state, qualysOS: action.payload.qualysOS };
    }

    if (action.type === 'LOAD_COMMON_DATA_SOURCE_NAMES') {
        return { ...state, sourceNames: action.payload.sourceNames };
    }

    if (action.type === 'LOGOUT_USER') {
        return '';
    }

    return state;
};