/* eslint-disable import/no-anonymous-default-export */

export default (state = {}, action) => {
    if (action.type === 'SET_SEARCH_SIDE_FILTERS') {
        return action.payload;
    }

    if (['LOGOUT_USER', 'RESET_AUTOCOMPLETE_DATA'].includes(action.type)) {
        return {};
    }

    return state;
};