import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { isEmail } from 'validator';
import CyberSecurity from '../../../apis/cyberSecurityData';
import ReCAPTCHA from "react-google-recaptcha";

const regex = /^[ A-Za-z0-9_.&?!’:',-\/()\n]*$/;
const regexEmail = /^[ A-Za-z0-9_.@&?!',-\/()]*$/;

const ContactUs = () => {
  //redux store state
  const currentUser = useSelector(state => state.currentUser);
  const accessToken = useSelector(state => state.accessToken);
  //comp local state
  const [confirm, setConfirm] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [responseError, setResponseError] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  //efect to initialize form values of there is a logged in user
  useEffect(() => {
    if (currentUser._id) {
      setName(currentUser.firstName + ' ' + currentUser.lastName);
      setEmail(currentUser.email);
    }
  }, [currentUser]);

  useEffect(() => {
    if (name && email && feedbackMessage) setShowRecaptcha(true);
    else setShowRecaptcha(false);
  }, [name, email, feedbackMessage]);


  const onSubmitClick = async () => {

    const newErrors = {};
    if (!feedbackMessage) newErrors.message = 'Please, enter your message';
    if (!name) newErrors.name = 'Please, enter your name';
    if (!email || !isEmail(email)) newErrors.email = 'Please, enter a valid email';

    const validatedMessage = feedbackMessage.match(regex);
    const validatedName = name.match(regex);
    const validatedEmail = email.match(regexEmail);

    if (!validatedMessage) newErrors.message = 'Please, remove any special characters';
    if (!validatedName) newErrors.name = 'Please, remove any special characters';
    if (!validatedEmail) newErrors.email = 'Please, enter a valid email';

    if (Object.keys(newErrors).length > 0) return setErrors(newErrors);
    else setErrors({});

    if (!recaptcha) return setErrors({ recaptcha: 'reCaptcha validation error, please try again' });
    else setErrors({});

    const messageData = {
      feedbackCategory: 'Landing Page Contact Us',
      firstName: name,
      email: email,
      message: feedbackMessage,
      recaptcha
    };

    const response = await CyberSecurity.post('/specificfeedback', messageData);

    if (response.data.error) return setResponseError(response.data.error);
    else setResponseError('');

    setConfirm(true);

    //reset Form
    setFeedbackMessage('');
    if (!currentUser.firstName) {
      setName('');
      setEmail('');
    }
    setTimeout(() => {
      setConfirm(false);
    }, 3000);
  };


  return (
    <div className="form-wrapper">
      <Form>
        <Form.Group className="mb-4" controlId="formBasicEmail">
          <Form.Label>Write here</Form.Label>
          <Form.Control as="textarea" rows={4} placeholder="Type details here.." value={feedbackMessage} onChange={(e) => setFeedbackMessage(e.target.value)} />
          {errors.message && <div className="error text-left mb-0" style={{ color: '#EA5454' }}>{errors.message}</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} disabled={currentUser._id ? true : false} />
          {errors.name && <div className="error text-left mb-0" style={{ color: '#EA5454' }}>{errors.name}</div>}
        </Form.Group>

        <Form.Group className="mb-4" controlId="exampleForm.ControlInput2">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} disabled={currentUser._id ? true : false} />
          {errors.email && <div className="error text-left mb-0" style={{ color: '#EA5454' }}>{errors.email}</div>}
        </Form.Group>

        <div className="text-right">
          <Button variant="primary" className="btn-gradient-red" onClick={onSubmitClick}>
            <span>SEND</span>
          </Button>
        </div>
      </Form>
      {errors.recaptcha && <div className="error text-center mb-0" style={{ color: '#EA5454' }}>{errors.recaptcha}</div>}
      <div className="d-flex justify-content-center">
        {showRecaptcha && <ReCAPTCHA
          sitekey="6Lc0_uEUAAAAAE29L9BpPI5PZMN6q2J67R4SN9Hj"
          onChange={setRecaptcha}
        />}
      </div>
    </div>
  );
};

export default ContactUs

