/* eslint-disable import/no-anonymous-default-export */

const initialState = 'date';

export default (state = initialState, action) => {
    if (action.type === 'SET_DETECTIONS_SORT_BY') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER' || action.type === 'RESET_DETECTIONS_SORT_BY') {
        return initialState;
    }

    return state;
};