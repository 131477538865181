/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    selectedEDR: '', // defender-edr or insights-idr
    edrScanResults: {},
    showEDRScanResults: false,
    scanningEDR: false,
    itemsBeingScanned: [],
    defenderURLs: [],
    crowdStrikeURLs: [],
    crowdStrikeDomain: "",
    showCrowdStrikeDomainSettingsModal: false,

    settings: {
        time: 'last 30 days',
        timeOptions: ['last 1 day', 'last 3 days', 'last 7 days', 'last 14 days', 'last 30 days'],
        insightsIDRSelectedLogSets: [],
        availableLogSets: [],
    },
};

export default (state = initialState, action) => {
    if (action.type === 'SET_SELECTED_EDR') {
        return { ...state, selectedEDR: action.payload };
    }

    if (action.type === 'SET_EDR_TIME_RANGE') {
        return { ...state, settings: { ...state.settings, time: action.payload } };
    }

    if (action.type === 'SET_INSIGHTS_IDR_SELECTED_LOG_SETS') {
        return { ...state, settings: { ...state.settings, insightsIDRSelectedLogSets: action.payload } };
    }

    if (action.type === 'SET_INSIGHTS_IDR_AVAILABLE_LOG_SETS') {
        return { ...state, settings: { ...state.settings, availableLogSets: action.payload } };
    }

    // -----------------------------------------  EDR SCAN RESULTS ------------------------------------------
    if (action.type === 'SET_EDR_SCAN_RESULTS') {

        return { ...state, edrScanResults: { ...state.edrScanResults, ...action.payload }, showEDRScanResults: true, scanningEDR: false, itemsBeingScanned: [] };
    }

    if (action.type === 'SET_BREACH_EDR_SCAN_RESULTS') { //payload: {breachId, results}

        return { ...state, edrScanResults: { ...state.edrScanResults, [action.payload.breachId]: action.payload.results }, showEDRScanResults: true, scanningEDR: false, itemsBeingScanned: [] };
    }

    if (action.type === 'SET_SHOW_EDR_SCAN_RESULTS') {
        return { ...state, showEDRScanResults: action.payload };
    }

    if (action.type === 'RESET_EDR_SCAN_RESULTS') {
        return { ...state, edrScanResults: {}, showEDRScanResults: false, scanningEDR: false, itemsBeingScanned: [] };
    }

    if (action.type === 'SET_SELECTED_EDR') {
        return { ...state, selectedEDR: action.payload, scanningEDR: false, itemsBeingScanned: [] };
    }

    if (action.type === 'SET_EDR_SCANNING') {
        return { ...state, scanningEDR: action.payload };
    }

    if (action.type === 'SET_EDR_ITEMS_BEING_SCANNED') {
        return { ...state, itemsBeingScanned: action.payload };
    }

    // -----------------------------------------  EDR EASY QUERY URLS ------------------------------------------
    if (action.type === 'SET_DEFENDER_URLS') {
        return { ...state, defenderURLs: [...state.defenderURLs, ...action.payload] };
    }

    if (action.type === 'SET_CROWDSTRIKE_URLS') {
        return { ...state, crowdStrikeURLs: [...state.crowdStrikeURLs, ...action.payload] };
    }

    if (action.type === 'SET_CROWDSTRIKE_DOMAIN') {
        return { ...state, crowdStrikeDomain: action.payload };
    }

    if (action.type === 'SET_CROWDSTRIKE_DOMAIN_SHOW_SETTINGS_MODAL') {
        return { ...state, showCrowdStrikeDomainSettingsModal: action.payload };
    }

    if (action.type === 'RESET_EDR_URLS') {
        return initialState;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};