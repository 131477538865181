/* eslint-disable import/no-anonymous-default-export */

export default (state = {}, action) => {

  if (action.type === 'GET_GRAPHS_COLORS') {
    return action.payload;
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return {}
  }

  return state
}