import React from 'react';
import ReactDOM from 'react-dom';
import { loadState, saveState } from './localStorage/localStorage';
import { throttle } from 'lodash';
import { createRoot } from 'react-dom/client';
//strip
// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

//redux
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";

import App from './components/App';
import reducers from './reducers';

const composeEnhancers = (process.env.REACT_APP_TRUKNO_BASE_URL !== 'https://www.trukno.com' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const persistedState = loadState(); //loading data from local storage

//creating Redux store and initialize it with data from the local storage and adding Thunk enhancer
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(thunk)));

//Saving state data to local storage every 1 second
store.subscribe(throttle(() => {
    saveState({
        accessToken: store.getState().accessToken,
        currentUser: store.getState().currentUser,
        searchPage: store.getState().searchPage,
        enterprise: store.getState().enterprise,
        subData: store.getState().subData,
        favoritesArray: store.getState().favoritesArray,
        toBeShared: store.getState().toBeShared,
        uniqueId: store.getState().uniqueId,
        // detections: store.getState().detections,
        // personalBoard: store.getState().personalBoard,
        // dashboard_Boards: store.getState().dashboard_Boards
    });
}, 1000));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <App />
        {/* <Elements stripe={stripePromise}>
            <App />
        </Elements> */}
    </Provider>
);


// strip added packages
// npm i stripe @stripe/react-stripe-js @stripe/stripe-js