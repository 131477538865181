/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    term: '',
    debouncedTerm: ''
};

export default (state = initialState, action) => {
    if (action.type === 'CHANGE_SEARCH_TERM') {
        return { ...state, term: action.payload };
    }

    if (action.type === 'CHANGE_DEBOUNCED_TERM') {
        return { ...state, debouncedTerm: action.payload };
    }

    if (['LOGOUT_USER'].includes(action.type)) {
        return initialState;
    }

    return state;
};