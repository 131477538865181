/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
const initialState = {
    inviteLink: '',
    activeBoard: '',
    activeChannel: '',
    boardFeed: [],
    feedFilters: {},
    userBoards: [],
}

export default (state = initialState, action) => {

    // -----------------------------------------  Active Board Actions ---------------------------------------------
    if (action.type === 'SET_ACTIVE_BOARD') {
        return { ...state, activeBoard: action.payload }
    }

    // -----------------------------------------  Active Board Actions ---------------------------------------------
    if (action.type === 'SET_INVIT_LINK') {
        return { ...state, inviteLink: action.payload };
    }

    // -----------------------------------------  Active Channel Actions ---------------------------------------------
    if (action.type === 'SET_ACTIVE_CHANNEL') {
        return { ...state, activeChannel: action.payload }
    }

    // -----------------------------------------  User Boards Actions ---------------------------------------------
    if (action.type === 'SET_USER_BOARDS') {
        return { ...state, userBoards: action.payload }
    }

    // -----------------------------------------  Board Feed Actions --------------------------------------------
    if (action.type === 'FETCH_BOARD_FEED') {
        return { ...state, boardFeed: action.payload }
    }

    if (action.type === 'ADD_NEW_FEED') {
        return { ...state, boardFeed: [...state.boardFeed, action.payload] }
    }

    if (action.type === 'UPDATE_FEED_ITEM') {
        console.log(action.payload)
        const itemToUpdate = state.boardFeed.filter(el => el._id === action.payload._id)[0];
        const updatedItem = { ...itemToUpdate, ...action.payload };
        console.log(updatedItem)
        return { ...state, boardFeed: state.boardFeed.map(el => el._id === updatedItem._id ? updatedItem : el) }
    }

    if (action.type === 'ADD_FEED_COMMENT') {
        //payload => new comment item + onwerData included + feedId included
        const updatedItem = state.boardFeed.filter(el => el._id === action.payload.feedId)[0];
        updatedItem.comments.push(action.payload);
        return { ...state, boardFeed: state.boardFeed.map(item => item._id === action.payload.feedId ? updatedItem : item) }
    }

    if (action.type === 'DELETE_FEED_ITEM') { //action.payload will be the deleted feed id
        return { ...state, boardFeed: state.boardFeed.filter(feed => feed._id !== action.payload) }
    }

    if (action.type === 'LOAD_MORE_FEEDS') {
        return { ...state, boardFeed: [...state.boardFeed, ...action.payload] };
    }

    if (action.type === 'UPDATE_FEED_COMMENT') {
        const comment = action.payload;
        const feedItem = state.boardFeed.filter(feed => feed._id === comment.feedId)[0];
        feedItem.comments = feedItem.comments.map(el => el._id === comment._id ? comment : el);
        return { ...state, boardFeed: state.boardFeed.map(feed => feed._id === feedItem._id ? feedItem : feed) };
    }

    if (action.type === 'DELETE_FEED_COMMENT') {
        const comment = action.payload;
        const feedItem = state.boardFeed.filter(feed => feed._id === comment.feedId)[0];
        feedItem.comments = feedItem.comments.filter(el => el._id !== comment._id);
        return { ...state, boardFeed: state.boardFeed.map(feed => feed._id === feedItem._id ? feedItem : feed) };
    }

    // -----------------------------------------  Board Feed filter Actions --------------------------------------------
    if (action.type === 'ADD_FEED_FILTER') {
        const newFilters = { ...state.feedFilters, [action.payload.key]: action.payload.value };
        return { ...state, feedFilters: newFilters }
    }

    if (action.type === 'REMOVE_FEED_FILTER') {
        const newFilters = _.omit(state.feedFilters, action.payload);
        return { ...state, feedFilters: newFilters }
    }

    if (action.type === 'RESET_FEED_FILTER') {
        return { ...state, feedFilters: {} }
    }

    // -----------------------------------------  Board Feed filter Actions -------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState
    }

    return state
}