import React, { useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactGa from 'react-ga';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { generateUniqeId } from '../actions/generateUniqueId';
import history from '../history';
import CyberSecurity from '../apis/cyberSecurityData';
import { logoutUser } from '../actions/userActions';
import { setVTContainer } from '../actions/VirusTotalActions/virusTotalActions';
import { isMobile } from 'react-device-detect';

// importing components and css (local files)
import NewLandingPage from './Pages/NewLandingPage/NewLandingPage';

import './css/AppLightTheme.scss';
import './css/DarkTheme.scss';

//actions
import { setIsTesting } from '../actions/isTestingActions';

const Modal = React.lazy(() => import('./Modals/modalTempelate'));
const HandleInvitations = React.lazy(() => import('./Pages/HandleInvitations/HandleInvitations'));
const JoinBeta = React.lazy(() => import('./Pages/HandleInvitations/JoinBeta'));
const ForgotPassword = React.lazy(() => import('./Pages/UserPages/ForgotPassword/ForgotPassword'));
const RequestDemo = React.lazy(() => import('./Pages/RequestDemo/RequestDemo'));
const PricingPage = React.lazy(() => import('./Pages/PricingPage/PricingPage'));
const ResourcesPage = React.lazy(() => import('./Pages/ResourcesPage/ResourcesPage'));
const BlogPage = React.lazy(() => import('./Pages/BlogPage/BlogPage'));
const BlogItemPage = React.lazy(() => import('./Pages/BlogItemPage/BreachesSection'));
const JoinEnterprise = React.lazy(() => import('./Pages/JoinEnterprise/JoinEnterprise'));
const ProfilePage = React.lazy(() => import('./Pages/Profile/ProfilePage'));

const Dashboard = React.lazy(() => import('./Pages/Dashboard'));

const App = (props) => {
  //redux store state
  const darkMode = useSelector(state => state.darkMode);
  const filters = useSelector(state => state.dashboard_NewsState.filters);
  const accessToken = useSelector(state => state.accessToken);
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGa.initialize([
      {
        trackingId: "UA-161840224-1",
        gaOptions: { name: "tracker01" },
      },
      { trackingId: "UA-133619160-2" }
    ]);

    // ReactGa.initialize('UA-161840224-1');
    ReactGa.pageview(history.location.pathname + history.location.search);
    if (!props.uniqueId) props.generateUniqeId();

    //checking stored info
    if (currentUser._id && accessToken) checkAuth();

    // checking if in testing mode
    if (process.env.REACT_APP_TRUKNO_BASE_URL.includes('staging') || process.env.REACT_APP_TRUKNO_BASE_URL.includes('localhost')) {
      dispatch(setIsTesting());
    }

    //checking if the logout cookie is set and remove it if it is
    if (document.cookie.includes('logout=true')) {
      document.cookie = "logout=true; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    //initialize virus total container
    if (!isMobile) dispatch(setVTContainer());

  }, [filters]);

  const checkAuth = async () => {
    try {
      const response = await CyberSecurity.post('/storedinfo', { id: currentUser._id }, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      });

    } catch (error) {
      localStorage.removeItem("state");
      dispatch(logoutUser());
    }
  };

  // effect to add / remove a "dark-mode" class to<body>
  // useEffect(() => {
  // darkMode ? document.body.classList.add('dark-mode') : document.body.classList.remove('dark-mode')
  //   darkMode ? document.querySelector('#dashboard').classList.add('dark-mode') : document.querySelector('#dashboard').classList.remove('dark-mode')
  // }, [darkMode])

  return (
    <div>
      <Modal />

      <Suspense fallback={<div>Loading...</div>}>
        <Router history={history}>
          <div>
            <Route path='/' exact component={NewLandingPage} />
            {/* <Route path='/oldlandingpage' exact component={NewLanding} />
          <Route path='/landingpage' exact component={LandingPage} /> */}
            <Route path='/pricing' exact component={PricingPage} />
            <Route path='/resources' exact component={ResourcesPage} />
            {/* <Route path='/team' exact component={TeamPage} /> */}
            {/* <Route path='/home' exact component={props.currentUser.userRole === 'admin' ? HomePage : LandingPage} /> */}
            {/* <Route path='/specialistrequests' component={ManageSpecialistRequests} /> */}
            {/* <Route path='/login' component={LinkedinLogin} /> */}
            <Route path='/resetpassword' component={ForgotPassword} />
            {/* <Route path='/linkedin_share' exact component={LinkedinShare} />
          <Route path='/linkedin_share/:id/:title' exact component={LinkedinShare} /> */}
            {/* <Route path='/feedbackmanager' component={props.currentUser.userRole === 'admin' ? FeddbackManager : HomePage} /> */}
            <Route path='/activation' component={HandleInvitations} history={history} />
            <Route path='/joinboard' component={HandleInvitations} history={history} />
            <Route path='/activateandjoinboard' component={HandleInvitations} history={history} />
            <Route path='/joinbeta' component={JoinBeta} history={history} />
            {/* <Route path='/mission' component={OurMission} /> */}
            {/* <Route path='/privacy' component={Privacy} /> */}
            {/* <Route path='/private/beta' component={PrivateJoin} /> */}
            <Route path='/demo' component={RequestDemo} />
            <Route path='/profile' component={ProfilePage} />
            <Route path='/joinenterprise' component={JoinEnterprise} />
            <Route path='/blog' exact component={BlogPage} />
            <Route path='/blog/:id' exact component={BlogItemPage} />

            <div>
              <Route path='/dashboard' component={Dashboard} history={history} />
            </div>
          </div>
        </Router>
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    uniqueId: state.uniqueId,
    darkMode: state.darkMode
  };
};

export default connect(mapStateToProps, { generateUniqeId })(App);
