/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const initialState = {
  allAssets: 0,
  allCves: 0,
  filteredCves: 0,
};

export default (state = initialState, action) => {

  if (action.type === 'SET_QUALYS_ALL_ASSETS_COUNT') {
    return { ...state, allAssets: action.payload };
  }
  if (action.type === 'SET_QUALYS_ALL_CVES_COUNT') {
    return { ...state, allCves: action.payload };
  }
  if (action.type === 'SET_QUALYS_FILTERED_CVES_COUNT') {
    return { ...state, filteredCves: action.payload };
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return initialState;
  }

  return state;
};