/* eslint-disable import/no-anonymous-default-export */

const initialState = 1;

export default (state = initialState, action) => {
    if (action.type === 'SET_DETECTIONS_LIST_PAGE') {
        return action.payload;
    }

    if (action.type === 'INCREMENT_DETECTIONS_LIST_PAGE') {
        return state + 1;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (
        action.type === 'LOGOUT_USER' ||
        action.type === 'ADD_DETECTION_FILTER' ||
        action.type === 'DELETE_DETECTION_FILTER' ||
        action.type === 'RESET_DETECTION_FILTER' ||
        action.type === 'RESET_DETECTIONS_LIST_PAGE'
    ) {
        return initialState;
    }

    return state;
};