/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = [], action) => {

  if (action.type === 'ADD_CARD') {
    return { ...state, [action.payload._id]: action.payload };
  }

  if (action.type === 'DUPLICATE_CARD') {
    return { ...state, [action.payload.newCard._id]: action.payload.newCard };
  }

  if (action.type === 'EDIT_CISO_CARD') {
    // return state.map(el => el._id === action.payload._id ? action.payload : el);
    return { ...state, [action.payload._id]: action.payload };
  }

  if (action.type === 'LOAD_CARDS') {

    const newItems = _.mapKeys(action.payload.cards, '_id');

    return newItems;
  }

  if (action.type === 'DELETE_CARD') {
    const ids = Object.keys(state);
    const newState = {};

    ids.forEach(id => {
      if (id !== action.payload) newState[id] = state[id];
    });
    return newState;
    // return { ...state, [action.payload]: undefined };
  }

  if (action.type === 'LOGOUT_USER') {
    return {};
  }

  return state;
};
