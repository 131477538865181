/* eslint-disable import/no-anonymous-default-export */

export default (state = [], action) => {
    if (action.type === 'EMAIL_LOGIN' || action.type === 'ADD_FAVORITE' || action.type === 'LINKEDIN_LOGIN') {
        return action.payload.favoritesArray;
    }

    if (action.type === 'LOGOUT_USER') {
        return [];
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return []
    }

    return state
}