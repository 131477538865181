/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = '', action) => {

    if (action.type === 'LOAD_CARDS') {
        if (action.payload?.pref?.activeGroup) return action.payload.pref.activeGroup;
        else return '';
    }

    if (action.type === 'SET_ACTIVE_GROUP') {
        return action.payload;
    }

    if (action.type === 'RENAME_GROUP') {
        if (state === action.payload.oldName) return action.payload.newName;
    }


    if (action.type === 'LOGOUT_USER') {
        return '';
    }

    return state;
};
