import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../actions/modalsActions';
import { emailLogin, activatedUser } from '../../actions/userActions';
import { Formik, Form, Field } from 'formik';
import { Form as bootStrapForm, InputGroup, Col, Button } from 'react-bootstrap';
import history from '../../history';
import * as yup from 'yup';
import { isMobile } from 'react-device-detect';

import { Icon, InlineIcon } from '@iconify/react';
import bxlLinkedinSquare from '@iconify-icons/bx/bxl-linkedin-square';



const schema = yup.object({
  email: yup.string().max(50).matches(/^[ A-Za-z0-9_.@&',-/()]*$/, 'Please enter a valid Email Address').email('Please enter a valid Email Address').required('This is a required field'),
  password: yup.string().max(50).required('This is a required field')
});

function LoginForm(props) {
  const [loginError, setLoginError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const renderTextField = ({ field, form, meta, checkHidden }, label) => {
    return (
      <bootStrapForm.Group>
        {/* <bootStrapForm.Label>{label}</bootStrapForm.Label> */}
        <bootStrapForm.Control isInvalid={meta.touched && meta.error} {...field} type="text" placeholder={label} />
        {meta.touched && meta.error && <div className="error text-left" style={{ color: '#EA5454' }}>{meta.error}</div>}
      </bootStrapForm.Group>
    );
  };

  const renderPasswordField = ({ field, form, meta, checkHidden }, label) => {
    return (
      <bootStrapForm.Group>
        {/* <bootStrapForm.Label>{label}</bootStrapForm.Label> */}
        <bootStrapForm.Control isInvalid={meta.touched && meta.error} {...field} type="password" placeholder={label} />
        {meta.touched && meta.error && <div className="error text-left" style={{ color: '#EA5454' }}>{meta.error}</div>}
      </bootStrapForm.Group>
    );
  };

  return (
    <Formik
      validationSchema={schema}
      // validate={values => validate(values)}
      onSubmit={async (values, { setErrors }) => {
        try {
          const response = await props.emailLogin(values);

          if (response.needsMFAVerification === true) {
            props.activatedUser({
              user: { mfaEnabled: response.userEnabledMFA },
              token: response.token,
              favoritesArray: []
            });
            props.setActiveForm('mfa-verify');
            return;
          }

          if (response.user) {
            props.setShowLoginModal(false);

            if (isMobile) history.push('/dashboard/news');
            else if (response.enterprise?.active === true) history.push('/dashboard/main');
            // else if (response.user.plan && (response.user.plan.includes('professional') || response.user.plan === 'trial')) history.push('/dashboard/main');
            else history.push('/dashboard/news');

          }
          if (response.error === 'Too Many Requests') setLoginError('Please wait 2 minutes and try again');
          else if (response.error === 'Please activate your account') {
            props.setRegisteredEmail(values.email);
            props.setActiveForm('confirm');
          }
          else setLoginError('Incorrect Email or Password');

        } catch (error) {
          console.log(error);
          if (error.response?.status === 429) setLoginError('Please wait 2 minutes and try again');
          else setLoginError('Incorrect Email or Password');
        }
      }}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        validateField,
        isSubmitting,
        reset
      }) => (
        <Form autoComplete="off" className="py-3">
          <div className="form-group-container--fixed-width">

            {/* <a href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77vndh3rm60v1w&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_LOGIN}&state=ebrahimsaed&scope=r_liteprofile%20r_emailaddress%20w_member_social`} className="btn btn-linkedin my-3">
              <Icon icon={bxlLinkedinSquare} style={{ color: "#0a66c2", fontSize: '32px' }} />
              <span className="d-inline-block pl-2">Login with Linkedin</span>
            </a>

            <span className="divider mb-3"><span>Or</span></span> */}

            <Field name="email">
              {({ field, form, meta }) => renderTextField({ field, form, meta }, 'Email*')}
            </Field>
            <Field name="password">
              {({ field, form, meta }) => renderPasswordField({ field, form, meta }, 'Password')}
            </Field>

            <div className="d-flex justify-content-start pb-3">
              <span className="forgot-password pointer" onClick={() => props.setActiveForm('reset-password')}>Forgot password?</span>
            </div>

            <div>

              {loginError && <strong style={{ color: '#EA5454' }}>{loginError}</strong>}

              <Button variant="primary" className="btn-custom w-100 mb-4" type="submit" disabled={submitting}>Log In</Button>

              {/* <p className="need-account">
                New to TruKno?
                <span style={{ cursor: "pointer" }}
                  onClick={() => {
                    // props.setShowLoginModal(false)
                    props.setActiveForm('signup')
                  }}
                >
                  Create a community account (limited access)
                </span>
              </p> */}

            </div>

          </div>
        </Form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    accessToken: state.accessToken,
    modelType: state.modalType
  };
};

export default connect(mapStateToProps, { hideModal, showModal, emailLogin, activatedUser })(LoginForm);
