import { combineReducers } from 'redux';

//reducers
import detectionsFiltersDataReducers from './detectionsFiltersDataReducers';
import detectionsSelectedFiltersReducers from './detectionsSelectedFiltersReducers';
import detectionsListReducers from './detectionsListReducers';
import detectionItemPageReducers from './detectionItemPageReducers';
import detectionsListPageReducers from './detectionsListPageReducers';
import detectionsListTotalCountReducers from './detectionsListTotalCountReducers';
import detectionsSearchTermReducers from './detectionsSearchTermReducers';
import detectionsListLoadingReducers from './detectionsListLoadingReducers';
import detectionsSortByReducers from './detectionsSortByReducers';
import detectionsBreachRelatedRules from './detectionsBreachRelatedRules';
import edrReducers from './edrReducers';

export default combineReducers({
    edr: edrReducers,
    list: detectionsListReducers,
    sortBy: detectionsSortByReducers,
    loadingList: detectionsListLoadingReducers,
    totalCount: detectionsListTotalCountReducers,
    page: detectionsListPageReducers,
    filters: detectionsFiltersDataReducers,
    selectedFilters: detectionsSelectedFiltersReducers,
    searchTerm: detectionsSearchTermReducers,
    itemPage: detectionItemPageReducers,
    breachRules: detectionsBreachRelatedRules,
});