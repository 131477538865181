/* eslint-disable import/no-anonymous-default-export */
export default (state = '', action) => {

    // -----------------------------------------  Setting the socket Actions ---------------------------------------------
    if (action.type === 'SET_SOCKET') {
        return action.payload
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return ''
    }

    return state
}