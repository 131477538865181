/* eslint-disable import/no-anonymous-default-export */

export default (state = [], action) => {
    if (action.type === 'SET_SEARCH_RELATED_BREACHES') {
        return action.payload;
    }

    if (['LOGOUT_USER'].includes(action.type)) {
        return [];
    }

    return state;
};