import cyberSecurityData from '../apis/cyberSecurityData';
import history from '../history'; // to be able to make programatic navs
import Cookies from 'js-cookie';

//Action to sign up the user by email
export const emailSignup = (data) => async (dispatch) => {
    const response = await cyberSecurityData.post('/users/emailsignup', data);

    // dispatch({
    //     type: 'EMAIL_SIGNUP',
    //     payload: response.data
    // })
    //Navigate to home page after email sign up
    // history.push('/home')
};

//Action to sign up the user by linkedin
export const linkedinSignup = (data) => async (dispatch) => {
    const response = await cyberSecurityData.post('/users/linkedinsignup', data);
    dispatch({
        type: 'LINKEDIN_SIGNUP',
        payload: response.data
    });
    dispatch({
        type: 'SET_ENTERPRISE',
        payload: response.data
    });
    // Navigate to home page after email sign up
    history.push('/dashboard/main');
};

//Action to sign up the user by email
export const emailLogin = (data) => async (dispatch) => {
    try {
        const response = await cyberSecurityData.post('/users/emailLogin', data);

        if (response.data.needsMFAVerification === true) {
            return response.data;
        }
        dispatch({
            type: 'EMAIL_LOGIN',
            payload: response.data
        });

        dispatch({
            type: 'SET_ENTERPRISE',
            payload: response.data
        });

        //getting personal boards channels and set active channel
        // if (response.data.token) {
        //     const channelsResponse = await cyberSecurityData.get('/pboards/channels', {
        //         headers: {
        //             Authorization: 'Bearer ' + response.data.token
        //         }
        //     })
        //     dispatch({
        //         type: 'LOAD_PERSONAL_CHANNELS',
        //         payload: channelsResponse.data
        //     })
        //     //if there are channels , set active channel
        //     if (channelsResponse.data && channelsResponse.data.length > 0) {
        //         dispatch({
        //             type: 'SET_PERSONAL_ACTIVE_CHANNEL',
        //             payload: channelsResponse.data[0]
        //         })
        //     }
        // }

        return response.data;

    } catch (error) {
        console.log(error.response);
        if (error.response.data === 'Please activate your account') return { error: 'Please activate your account' };
        if (error.response?.status === 429) return { error: 'Too Many Requests' };
    }
};

//Action to login user by linkedin
export const linkedinLogin = (data) => {
    return ({
        type: 'LINKEDIN_LOGIN',
        payload: data
    });
};

//Action to Log out the user
export const logoutUser = () => async (dispatch, getState) => {
    const token = getState().accessToken;

    //dispatching first to clear the user from redux store in case the tken is not valid
    dispatch({
        type: 'LOGOUT_USER',
    });

    await cyberSecurityData.post('/users/logout', {}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    //Navigate to home page after email Log In
    Cookies.set('logout', 'true', { expires: 7, secure: true });
    window.location.href = `${process.env.REACT_APP_TRUKNO_NEXT_URL}?logout=true`;
    // history.push('/');
};

//activated user
export const activatedUser = (payload) => {
    return {
        type: 'EMAIL_LOGIN',
        payload
    };
};

//Action to add a favorite
export const addFavorite = (itemId, itemCategory) => async (dispatch, getState) => {
    let data = { itemId };
    if (itemCategory) data.itemCategory = itemCategory;

    const response = await cyberSecurityData.post('/addfavorite', data,
        {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

    dispatch({
        type: 'ADD_FAVORITE',
        payload: response.data
    });
};

//Action to add a favorite
export const addPersonalTag = (label, color) => async (dispatch, getState) => {

    const response = await cyberSecurityData.post('/users/personaltag', { label, color },
        {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

    dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
            user: response.data
        }
    });

    if (response.data && response.data.personalTags) return response.data.personalTags;

};

//action to update user data
export const updateProfile = (user) => {
    return {
        type: 'UPDATE_PROFILE',
        payload: {
            user
        }
    };
};

//action to update the access token
export const directLogin = (user, token, callback) => (dispatch, getState) => {
    dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
            user
        }
    });
    dispatch({
        type: 'UPDATE_TOKEN',
        payload: {
            token
        }
    });
    callback();
};