/* eslint-disable import/no-anonymous-default-export */

export default (state = [], action) => {

    if (action.type === 'SET_THREATS_LIST') {
        let { items: data, sortOrder } = action.payload;
        //1. check if the sort is by latest breaches (which means there was a grouping in the backend in the aggregate which leads to data is coming in array of el => {_id: data, items: [items], count: items count for the date} )
        if (data && data[0] && data[0].items) { // if true means that there were a grouping by date in the backend
            //1. sorting the array
            if (sortOrder === -1) data.sort((a, b) => new Date(b._id) - new Date(a._id))
            if (sortOrder === 1) data.sort((a, b) => new Date(a._id) - new Date(b._id))
        }
        return data;
    }

    if (action.type === 'LOAD_MORE_THREATS') {
        let { items: data, sortOrder } = action.payload;
        //1. combine dates together
        let oldState = [...state];
        console.log(oldState)
        //check if the sort is by latest breaches (which means there was a grouping in the backend in the aggregate which leads to data is coming in array of el => {_id: data, items: [items], count: items count for the date} )
        if (data && data[0] && data[0].items) { // if true means that there were a grouping by date in the backend
            oldState = mergeArrays(oldState, data)
        } else {
            oldState = [...state, ...data];
        }

        //2. sorting the array
        if (sortOrder === -1) oldState.sort((a, b) => new Date(b._id) - new Date(a._id))
        if (sortOrder === 1) oldState.sort((a, b) => new Date(a._id) - new Date(b._id))

        //3. returning the final array
        console.log(oldState)
        return oldState;
    }

    if (action.type === 'SET_THREATS_ITEM') {
        return [action.payload];
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return []
    }

    return state
}

const mergeArrays = (arr1, arr2) => {
    //1. create set of unique ids
    let set = new Set(arr1.concat(arr2).map(el => el._id))
    const ids = Array.from(set)

    let finalArray = [];
    ids.forEach(id => {
        let el1 = arr1.filter(el => el._id === id)[0];
        let el2 = arr2.filter(el => el._id === id)[0];

        if (el1 && el2) {
            const newItems = el1.items.concat(el2.items);
            const newCount = el1.count + el2.count;
            finalArray.push({
                _id: el1._id,
                items: newItems,
                count: newCount
            })
        } else {
            if (el1) finalArray.push(el1);
            if (el2) finalArray.push(el2);
        }
    })
    return finalArray
}