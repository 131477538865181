/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const initialState = {
    time: '30 Days',
};

export default (state = initialState, action) => {

    if (action.type === 'ADD_DETECTION_FILTER') {
        return { ...state, [action.payload.key]: action.payload.value };
    }

    if (action.type === 'DELETE_DETECTION_FILTER') {
        const newFilters = _.omit(state, action.payload);
        return newFilters;
    }

    if (action.type === 'RESET_DETECTION_FILTER') {
        return initialState;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};