/* eslint-disable import/no-anonymous-default-export */

const initialValues = {
  names: [],
  trendingNames: [],
  storyNamesOptions: []
}

export default (state = initialValues, action) => {

  if (action.type === 'SET_STORY_NAMES') {
    return { ...state, names: action.payload }
  }

  if (action.type === 'ADD_STORY_NAME') {
    return { ...state, storyNamesOptions: [...state.storyNamesOptions, action.payload] };
  }

  if (action.type === 'SET_TRENDING_NAMES') {
    return { ...state, trendingNames: action.payload }
  }

  if (action.type === 'SET_STORY_NAMES_OPTIONS') {
    return { ...state, storyNamesOptions: action.payload }
  }

  return state
}