import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player';

const VideoModal = ({ showModal, setShowModal }) => {

    const onModalHide = () => {
        setShowModal(false);
    };

    return (
        <Modal show={showModal} onHide={onModalHide} dialogClassName="video-modal">
            <Modal.Header closeButton>
            </Modal.Header>


            <Modal.Body className="p-0">
                <div className="iframe-container">
                    {/* <ReactPlayer width="100%" height="100%" url={'https://www.youtube.com/watch?v=kviLv25PHBg&t=1s'} playsinline controls /> */}
                    <ReactPlayer width="100%" height="100%" url={'https://trukno.s3.us-east-2.amazonaws.com/Videos/TruKno+Landing+Page+Video+Rev+12+(FINAL).mp4'} playsinline controls playing />
                </div>
            </Modal.Body>
        </Modal >
    );
};

export default VideoModal;