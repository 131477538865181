/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = 'articlesCount', action) => {

  if (action.type === 'SET_SORT_BY_ASSETS') {
    return action.payload;  // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
  }

  return state;
};