import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CyberSecurity from '../../apis/cyberSecurityData';
import { isMobile } from 'react-device-detect';
import history from '../../history';

//actions
import { activatedUser } from '../../actions/userActions';
import { setEnterprise } from '../../actions/enterpriseActions/enterpriseActions';

const MFAVerifyForm = ({ setShowLoginModal }) => {
    //redux
    const accessToken = useSelector(state => state.accessToken);
    const currentUser = useSelector(state => state.currentUser);
    const dispatch = useDispatch();

    const [error, setError] = useState('');
    const [enteredCode, setEnteredCode] = useState('');

    const [showMFASettings, setShowMFASettings] = useState(false);
    const [QRImage, setQRImage] = useState(undefined);

    const getQRCode = async () => {
        try {
            const response = await CyberSecurity.get('mfa_qr_code', {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
                responseType: 'arraybuffer'
            });
            setQRImage(`data:${response.headers['content-type']};base64,${btoa(String.fromCharCode(...new Uint8Array(response.data)))}`);
            setShowMFASettings(true);
        } catch (error) {
            console.log({ error: error.message });
        }
    };

    useEffect(() => {
        if (currentUser.mfaEnabled === false) getQRCode();
    }, [currentUser]);

    const onVerifyClick = async () => {
        if (!enteredCode) return setError('Please enter code');
        setError('');

        try {

            const response = await CyberSecurity.post('/verify_otp', { code: enteredCode }, {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            });

            if (response.data?.user) {
                dispatch(activatedUser(response.data));
                // if (response.data.enterprise) dispatch(setEnterprise(response.data.enterprise));

                setShowLoginModal(false);

                if (isMobile) history.push('/dashboard/news');
                else if (response.data.enterprise?.active === true) history.push('/dashboard/main');
                // else if (response.user.plan && (response.user.plan.includes('professional') || response.user.plan === 'trial')) history.push('/dashboard/main');
                else history.push('/dashboard/news');
            }
        } catch (error) {
            if (error.response?.status === 401) setError('Process took so long please login again');
            console.log({ error: error.message });
        }
    };

    return (
        <div>
            <h5 className="text-center">Tow-Factor Authentication</h5>

            {showMFASettings && <div className='p-3'>
                {QRImage && <div>
                    <div className="d-flex justify-content-center py-3">
                        <img src={QRImage} alt="qrcode" />
                    </div>

                    <span className="d-block text-center pb-4">Please use the MFA application of your choice
                        (such as the <strong>Google Authenticator</strong> app)
                        to scan this QR Code and set access to your account.
                    </span>
                </div>}
            </div>}

            <span className="d-block text-center pb-4">Check your authenticator app for a code</span>
            <div className="px-5">
                <input type="text" className="form-control text-center" value={enteredCode} onChange={e => setEnteredCode(e.target.value)} />
            </div>
            {error && <div className="input-group--mfa mb-3 d-flex align-items-center justify-content-center">
                <p className='text-red'>{error}</p>
            </div>}

            <div className="d-flex align-items-center justify-content-center pt-3 pb-4">
                <button type="button" className="btn btn-primary" onClick={onVerifyClick}>Submit</button>
            </div>
        </div>
    );
};

export default MFAVerifyForm;