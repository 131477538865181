import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { isEmail } from 'validator';
import { useSelector, useDispatch } from 'react-redux';
import CyberSecurity from '../../../apis/cyberSecurityData';
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../../../images/Trukno-logo-full-white.svg';
//icons
import { Icon } from '@iconify/react';
import bxsLock from '@iconify-icons/bx/bxs-lock';
import ConfirmModal from '../../Pages/RequestDemo/ConfirmModal';

const RequestAccessModal = ({ showRequestAccessModal, setShowRequestAccessModal, enteredEmail }) => {
  //local
  const darkMode = useSelector(state => state.darkMode);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [comment, setComment] = useState('');
  const [error, setError] = useState('');
  const [recaptcha, setRecaptcha] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  useEffect(() => {
    if (enteredEmail) setEmail(enteredEmail);
    else setEmail('');
  }, [enteredEmail]);

  const onModalHide = () => {
    //ressetting the form
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompany('');
    setJobTitle('');
    setComment('');
    setShowRequestAccessModal(false);
  };

  const onRequestClick = async () => {
    if (!firstName) return setError('Please enter your first name');
    if (!lastName) return setError('Please enter your last name');
    if (!email || !isEmail(email)) return setError('Please enter a valid email');
    if (!company) return setError('Please enter your company name');
    if (!jobTitle) return setError('Please enter your job title');
    if (!recaptcha) return setError('Please complete the ReCAPTCHA');
    setError('');

    const data = {
      firstName,
      lastName,
      email,
      company,
      jobTitle,
      comment
    };

    await CyberSecurity.post('/requestaccess', data);
    setShowConfirmModal(true);
    onModalHide();
  };

  const onRecaptchaChange = (value) => {
    setRecaptcha(value);
  };

  const renderLogo = () => {
    if (darkMode === true) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" style={{ maxWidth: "60px" }} className="img-fluid" />;
    if (darkMode === false) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" style={{ maxWidth: "60px" }} className="img-fluid" />;
  };


  return (
    <>
      <Modal show={showRequestAccessModal} onHide={onModalHide} centered dialogClassName="login-signup" className="login">

        <Modal.Header closeButton>
          <Modal.Title className="w-100">

          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="text-center">

          <div className="contact-us__form">
            <div className="text-center mb-4">
              {renderLogo()}
            </div>
            <h3 className="text-center mb-5">Request a 30-Day Free Enterprise Trial</h3>
            <Row>
              <Col md={6}>
                <Form.Group controlId="formFirstName">
                  <Form.Control type="text" placeholder="First name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="formLastName">
                  <Form.Control type="text" placeholder="Last name" value={lastName} onChange={e => setLastName(e.target.value)} />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="formEmail">
              <Form.Control type="email" placeholder="Business Email" value={email} onChange={e => setEmail(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formCompany">
              <Form.Control type="text" placeholder="Company" value={company} onChange={e => setCompany(e.target.value)} />
            </Form.Group>

            <Form.Group controlId="formJob">
              <Form.Control type="text" placeholder="Job Title" value={jobTitle} onChange={e => setJobTitle(e.target.value)} />
            </Form.Group>

            <Form.Control as="textarea" rows={3} placeholder="Comment (optional)" value={comment} onChange={e => setComment(e.target.value)} />

            <div className="text-center pt-4">
              {error && <div className="error text-center" style={{ color: '#EA5454' }}>{error}</div>}
              <Button variant="primary" size="lg" onClick={onRequestClick}>
                Request Access
              </Button>
              <div className="d-flex justify-content-center mt-2">
                {firstName && <ReCAPTCHA
                  sitekey="6Lc0_uEUAAAAAE29L9BpPI5PZMN6q2J67R4SN9Hj"
                  onChange={onRecaptchaChange}
                />}
              </div>
            </div>

            <div className="pt-3 text-light-grey-md"><span className="d-flex justify-content-center"> <Icon icon={bxsLock} style={{ fontSize: '18px' }} className="mr-2" /> <span>We won't share your info with anybody</span></span></div>
          </div>

        </Modal.Body>
      </Modal >
      <ConfirmModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} />
    </>
  );
};

export default RequestAccessModal;
