/* eslint-disable import/no-anonymous-default-export */

export default (state = 0, action) => {
    if (action.type === 'LOAD_BLOG_ITEMS') {
        return action.payload.totalCount;
    }

    if (action.type === 'LOGOUT_USER' || action.type === 'RESET_BLOG_ITEMS') {
        return 0;
    }

    return state;
};