import CyberSecurity from '../apis/cyberSecurityData';
import history from '../history';
import Cookies from 'js-cookie';

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {
            return undefined;
        }

        const data = JSON.parse(serializedState);

        //get user and token from cookies and set it to the state if they exists on the cookies
        // const user = Cookies.get('currentUser');
        // const token = Cookies.get('token');
        // const enterprise = Cookies.get('enterprise');

        // if (user && token && enterprise) {
        //     data.currentUser = JSON.parse(user);
        //     data.accessToken = token;
        //     data.enterprise = JSON.parse(enterprise);
        // }

        if (checkForBadState(data)) {
            history.push('/');
            return undefined;
        };

        return data;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

const checkForBadState = (data) => {
    //checking for bad saved data
    if (data.currentUser && data.currentUser.preference && data.currentUser.preference.newsFeedPreference && data.currentUser.preference.newsFeedPreference.default === 'TruKno News Feed') return true;
    if (data.currentUser && data.currentUser.preference && data.currentUser.preference.newsFeedPreference && data.currentUser.preference.newsFeedPreference.default === 'Trukno Top 25') return true;
    // if (!data.currentUser.preference) return true;
    // if (!data.currentUser.preference.newsFeedPreference) return true;
    // if (!data.currentUser.preference.newsFeedPreference.siteDemo) return true;
    return false;
};
