/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = [], action) => {

    if (action.type === 'LOAD_CARDS') {
        if (action.payload?.pref?.groupCardsOrder) {
            return Object.keys(action.payload.pref.groupCardsOrder);
        }
        else return [];
    }

    if (action.type === 'RENAME_GROUP') {
        return state.map(el => el === action.payload.oldName ? action.payload.newName : el);
    }

    if (action.type === 'DELETE_GROUP') {
        return state.filter(el => el !== action.payload);
    }

    if (action.type === 'LOGOUT_USER') {
        return [];
    }

    return state;
};
