/* eslint-disable import/no-anonymous-default-export */

export default (status = true, action) => {
  if (action.type === 'TOGGLE_DARK_MODE') {

    return action.payload
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return true
  }

  return status
}