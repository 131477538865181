import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { Form as bootStrapForm, InputGroup, Col, Button, FormControl, Spinner, Row } from 'react-bootstrap';
import CyberSecurity from '../../apis/cyberSecurityData';
import { directLogin } from '../../actions/userActions';
import ReCAPTCHA from "react-google-recaptcha";
import history from '../../history';
import * as yup from 'yup';
//icons
import { Icon } from '@iconify/react';
import bxlLinkedinSquare from '@iconify-icons/bx/bxl-linkedin-square';
//free email domains
import freeEmailDomains from 'free-email-domains';


const schema = yup.object({
    firstName: yup.string().max(20).matches(/^[0-9a-zA-Z ]+$/, 'No special charachters').required('This is a required field'),
    lastName: yup.string().max(20).matches(/^[0-9a-zA-Z ]+$/, 'No special charachters').required('This is a required field'),
    companyName: yup.string().max(20).matches(/^[0-9a-zA-Z ]+$/, 'No special charachters'),
    email: yup.string().max(50).matches(/^[ A-Za-z0-9_.@&',-\/()]*$/, 'Please enter a valid Email Address').email('Please enter a valid Email Address').required('This is a required field'),
    password: yup.string().min(8).max(50).required('This is a required field')
});

function SignupBasicInfo({ setActiveForm, setShowLoginModal, email, setRegisteredEmail }) {
    const dispatch = useDispatch();
    //local comp state
    const [emailChecked, setEmailChecked] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);

    const renderTextField = ({ field, form, meta, checkHidden }, label) => {
        return (
            <bootStrapForm.Group>
                {/* <bootStrapForm.Label>{label}</bootStrapForm.Label> */}
                <bootStrapForm.Control isInvalid={meta.touched && meta.error} {...field} type="text" placeholder={label} autoComplete="new-password" />
                {meta.touched && meta.error && <div className="error text-left" style={{ color: '#EA5454' }}>{meta.error}</div>}
            </bootStrapForm.Group>
        );
    };

    const renderPasswordField = ({ field, form, meta, checkHidden }, label) => {
        return (
            <bootStrapForm.Group>
                {/* <bootStrapForm.Label>{label}</bootStrapForm.Label> */}
                <bootStrapForm.Control isInvalid={meta.touched && meta.error} {...field} type="password" placeholder={label} autoComplete="new-password" />
                {meta.touched && meta.error && <div className="error text-left" style={{ color: '#EA5454' }}>{meta.error}</div>}
            </bootStrapForm.Group>
        );
    };

    const onRecaptchaChange = (value) => {
        setRecaptcha(value);
    };

    return (
        <Formik
            validationSchema={schema}
            onSubmit={async (values, { setErrors }) => {
                if (recaptcha) {

                    //checking if emails exists
                    const response = await CyberSecurity.post('/users/checkemail', { email: values.email });
                    if (response.data.message === 'Email is Taken') setErrors({ email: 'There is already an account associated with this email' });
                    else {
                        //checking if the email is a free email
                        let domain;
                        if (values.email) {
                            domain = values.email.substr(values.email.indexOf('@') + 1);
                            if (freeEmailDomains.includes(domain.toLowerCase())) {
                                return setErrors({ email: 'Only business emails are allowed' });
                            }
                        }
                        const response = await CyberSecurity.post('/users/emailsignup', values);

                        if (!response.data.error) {
                            setRegisteredEmail(values.email);
                            setActiveForm('confirm');
                            // dispatch(directLogin(response.data.user, response.data.token, () => {
                            //     setShowLoginModal(false)
                            //     history.push('/dashboard/news');
                            // }))
                        }
                    }

                } else setErrors({ recaptcha: 'reCaptcha validation error, please try again' });

            }}
            initialValues={{
                firstName: '',
                lastName: '',
                companyName: '',
                email: email ? email : '',
                password: '',
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                validateField,
                isSubmitting
            }) => (
                <div className="form-group-container--fixed-width">

                    {/* <a href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77vndh3rm60v1w&redirect_uri=${process.env.REACT_APP_LINKEDIN_REDIRECT_LOGIN}&state=ebrahimsaed&scope=r_liteprofile%20r_emailaddress%20w_member_social`} className="btn btn-linkedin my-3">
                        <Icon icon={bxlLinkedinSquare} style={{ color: "#0a66c2", fontSize: '32px' }} />
                        <span className="d-inline-block pl-2">Signup with Linkedin</span>
                    </a>

                    <span className="divider mb-3"><span>Or</span></span> */}

                    <Form autoComplete="off">

                        <Row>
                            <Col>
                                <Field name="firstName">
                                    {({ field, form, meta }) => renderTextField({ field, form, meta }, 'First Name*')}
                                </Field>
                            </Col>
                            <Col>
                                <Field name="lastName">
                                    {({ field, form, meta }) => renderTextField({ field, form, meta }, 'Last Name*')}
                                </Field>
                            </Col>
                        </Row>




                        <Field name="email">
                            {({ field, form, meta }) => renderTextField({ field, form, meta }, 'Business Email*')}
                        </Field>
                        <Field name="password">
                            {({ field, form, meta }) => renderPasswordField({ field, form, meta }, 'Password')}
                        </Field>
                        <Field name="companyName">
                            {({ field, form, meta }) => renderTextField({ field, form, meta }, 'Company Name')}
                        </Field>
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                                sitekey="6Lc0_uEUAAAAAE29L9BpPI5PZMN6q2J67R4SN9Hj"
                                onChange={onRecaptchaChange}
                            />
                        </div>
                        {errors.recaptcha && <div className="error text-left" style={{ color: '#EA5454' }}>{errors.recaptcha}</div>}
                        <div className="py-4">
                            {/* {error && <strong>{error}</strong>} */}
                            <Button variant="primary" className="btn-custom w-100" type="submit" disabled={submitting}>
                                Continue
                            </Button>

                            <p className="need-account">
                                Already have an account?
                                <span style={{ cursor: "pointer", textDecoration: 'underline' }} onClick={() => setActiveForm('login')}>Login</span>
                            </p>
                        </div>

                    </Form>
                </div>
            )}
        </Formik>
    );
}

export default SignupBasicInfo;
