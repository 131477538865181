import { combineReducers } from 'redux';

import assets from './qualysAssetsItemsReducer';
import filters from './qualysAssetsFiltersReducer';
import sortBy from './qualysAssetsSortByReducer';
import loading from './qualysAssetsLoadingReducer';
import qualysAssetsCountDataReducer from './qualysAssetsCountDataReducer';
import page from './qualysAssetsPageReducer';

export default combineReducers({
  //Custom User Dashboard
  page,
  assets,
  filters,
  sortBy,
  loading,
  filteredAssetsCount: qualysAssetsCountDataReducer,
});