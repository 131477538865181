/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = true, action) => {

  if (
    ['SET_SORT_BY_ASSETS', 'ADD_FILTER_ASSETS', 'DELETE_FILTER_ASSETS', 'RESET_FILTERS_ASSETS'].includes(action.type)
  ) {
    return true;
  }

  if (
    ['SET_ASSETS'].includes(action.type)
  ) {
    return false;
  }

  return state;
};