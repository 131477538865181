import { combineReducers } from 'redux';

import qualysCVEs from './qualysCVEsReducer';
import qualysFilters from './qualysFiltersReducer';
import sortBy from './qualysSortByReducer';
import loading from './qualysLoadingReducer';
import countData from './qualysCountDataReducer';
import qualysPageReducer from './qualysPageReducer';

export default combineReducers({
  //Custom User Dashboard
  page: qualysPageReducer,
  cves: qualysCVEs,
  filters: qualysFilters,
  sortBy,
  loading,
  countData,
});