/* eslint-disable import/no-anonymous-default-export */

export default (state = 1, action) => {

    if (action.type === 'SET_BREACHES_LIST_PAGE') {
        return action.payload;
    }
    if (action.type === 'RESET_BREACHES_SECTION_PAGE') {
        return 1;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return 1
    }

    return state
}