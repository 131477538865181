/* eslint-disable import/no-anonymous-default-export */


export default (state = false, action) => {
    if (action.type === 'CHANGE_EXACT_MATCH') {
        return action.payload
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return false
    }

    return state;
}