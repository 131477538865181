/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const initialState = 1;

export default (state = initialState, action) => {

  if (action.type === 'INCREMENT_PAGE_QUALYS') {

    return state + 1;
  }

  if (
    [
      'RESET_PAGE_QUALYS',
      'ADD_FILTER_QUALYS',
      'DELETE_FILTER_QUALYS',
      'RESET_FILTERS_QUALYS',
      'SET_FILTERS_QUALYS',
    ].includes(action.type)
  ) {
    return 1;
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return initialState;
  }

  return state;
};