/* eslint-disable import/no-anonymous-default-export */
export default (state = 1, action) => {
    if (action.type === 'NEXT_PAGE') {
        const newPage = state + 1;
        return newPage;
    }

    if (action.type === 'RESET_PAGE') {
        return 1;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return 1
    }

    return state;
}