import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ showConfirmModal, setShowConfirmModal }) => {
  //redux
  const darkMode = useSelector(state => state.darkMode);

  const onModalHide = () => {
    setShowConfirmModal(false);
  };

  const renderLogo = () => {
    if (darkMode === true) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" style={{ maxWidth: "60px" }} className="img-fluid" />;
    if (darkMode === false) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" style={{ maxWidth: "60px" }} className="img-fluid" />;
  };

  return (
    <Modal show={showConfirmModal} onHide={onModalHide} centered className="modal-primary">
      <Modal.Header className="pr-0">
      </Modal.Header>

      <Modal.Body className="pb-0 pr-3">
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          {renderLogo()}
          <h3 className="mt-4">We have recieved your request,</h3>
          <p>TruKno team will soon get back to you.</p>

          <Button onClick={onModalHide} className="mt-4">Close</Button>

        </div>
      </Modal.Body>
    </Modal >
  );
};

export default ConfirmModal;
