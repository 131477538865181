/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = true, action) => {

  if (
    ['SET_SORT_BY_TENABLE', 'ADD_FILTER_TENABLE', 'DELETE_FILTER_TENABLE', 'RESET_FILTERS_TENABLE'].includes(action.type)
  ) {
    return true;
  }

  if (
    ['SET_CVES_TENABLE'].includes(action.type)
  ) {
    return false;
  }

  return state
}