/* eslint-disable import/no-anonymous-default-export */

const initialState = [];

export default (state = initialState, action) => {
    if (action.type === 'LOAD_DETECTIONS_LIST') {
        console.log(action.payload.results);
        if (action.payload.results?.length === 0) return [];
        return action.payload.results;
    }

    if (action.type === 'LOAD_MORE_DETECTIONS_LIST') {
        return [...state, ...action.payload.results];
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};