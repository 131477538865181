/* eslint-disable import/no-anonymous-default-export */

const initialState = {
  videos: [],
  pressReleases: [],
  csoWeekly: [],
  totalVideosCount: 0,
  totalReleasesCount: 0,
  totalCSOWeeklyCount: 0,
  filter: 'all' // all | videos | pressReleases | csoWeekly
}

export default (state = initialState, action) => {
  if (action.type === 'LOAD_VIDEOS') {
    return { ...state, videos: action.payload }
  }
  if (action.type === 'LOAD_RELEASES') {
    return { ...state, pressReleases: action.payload }
  }
  if (action.type === 'LOAD_CSOWEEKLY') {
    return { ...state, csoWeekly: action.payload }
  }
  if (action.type === 'SET_VIDEOS_COUNT') {
    return { ...state, totalVideosCount: action.payload }
  }
  if (action.type === 'SET_RELEASES_COUNT') {
    return { ...state, totalReleasesCount: action.payload }
  }
  if (action.type === 'SET_CSOWEEKLY_COUNT') {
    return { ...state, totalCSOWeeklyCount: action.payload }
  }
  if (action.type === 'SET_RESOURCES_FILTER') {
    return { ...state, filter: action.payload }
  }

  return state;
}