import cyberSecurityData from '../../apis/cyberSecurityData';

// -----------------------------------------  First Load Actions ------------------------------------------
export const setFirstLoadMainDashboard = () => {
    return {
        type: 'SET_FIRST_LOAD_MAIN_DASHBOARD'
    };
};

// -----------------------------------------  First Load Actions ------------------------------------------
export const setMainDashboardView = (view) => {
    return {
        type: 'SET_MAIN_DASHBOARD_VIEW',
        payload: view
    };
};
// -----------------------------------------  Page Actions ------------------------------------------
export const incrementMainDashboardPage = () => {
    return {
        type: 'INCREMENT_MAIN_DASHBOARD_PAGE'
    };
};

export const resetMainDashboardPage = () => {
    return {
        type: 'RESET_MAIN_DASHBOARD_PAGE'
    };
};

// -----------------------------------------  Category Actions ------------------------------------------
export const setMainDashboardCategory = (category) => {
    return {
        type: 'SET_MAIN_DASHBOARD_CATEGORY',
        payload: category
    };
};

// -----------------------------------------  Items Actions ------------------------------------------
export const getMainDashboardItems = (callback) => async (dispatch, getState) => {

    const page = getState().dashboard_Main.page;
    const category = getState().dashboard_Main.category;
    let filters = getState().dashboard_Main.filters;
    let aggregateTTPs = getState().dashboard_Main.aggregateTTPs;
    const favorites = getState().dashboard_Main.favorites;
    const favoritesArray = getState().favoritesArray;
    const sortOrder = getState().dashboard_Main.sortOrder;
    const threatsSortBy = getState().dashboard_Main.threatsSortBy;
    const breachesSortBy = getState().dashboard_Main.breachesSortBy;
    const malwareSortBy = getState().dashboard_Main.malwareSortBy;
    const actorsSortBy = getState().dashboard_Main.actorsSortBy;
    const cvesSortBy = getState().dashboard_Main.cvesSortBy;
    const filtersPreference = getState().currentUser.preference ? getState().currentUser.preference.mainDashboardFilters : '';

    if (filtersPreference && !getState().dashboard_Main.firstLoad && filtersPreference.default) {
        dispatch({
            type: 'SET_FIRST_LOAD_MAIN_DASHBOARD'
        });
        dispatch({
            type: 'SET_SELECTED_FILTERS_PRESET_MAIN_DASHBOARD',
            payload: filtersPreference.default
        });
        const defaultFilters = filtersPreference[filtersPreference.default];
        filters = defaultFilters;
        dispatch({
            type: 'SET_FILTERS_MAIN_DASHBOARD',
            payload: defaultFilters
        });
    }

    let endpoint = '';
    if (category === 'threats') endpoint = '/maindashboard/threats';
    if (category === 'breaches') endpoint = '/maindashboard/breaches';
    if (category === 'malware') endpoint = '/maindashboard/malware';
    if (category === 'actors') endpoint = '/maindashboard/actors';
    if (category === 'cves') endpoint = '/maindashboard/cves';

    const response = await cyberSecurityData.post(endpoint, {
        page, filters, aggregateTTPs, sortOrder, threatsSortBy, breachesSortBy, malwareSortBy, actorsSortBy, cvesSortBy, favorites, favoritesArray
    }, {
        headers: {
            Authorization: `Bearer ${getState().accessToken}`
        }
    });

    if (page === 1) dispatch({
        type: 'SET_MAIN_DASHBOARD_ITEMS',
        payload: response.data
    });
    else dispatch({
        type: 'LOAD_MORE_MAIN_DASHBOARD_ITEMS',
        payload: response.data
    });

};

export const setMainDashboardItems = (items) => {
    return {
        type: 'SET_MAIN_DASHBOARD_ITEMS',
        payload: items
    };
};

export const setMainDashboardAggregateTTPs = (state) => {
    return {
        type: 'SET_MAIN_DASHBOARD_AGGREGATE_TTPS',
        payload: state
    };
};

export const setMainDashboardMalwareChartSelection = (selection) => {
    return {
        type: 'SET_MAIN_DASHBOARD_MALWARE_FILTER_SELECTION',
        payload: selection
    };
};

export const loadMoreMainDashboardItems = (items) => {
    return {
        type: 'LOAD_MORE_MAIN_DASHBOARD_ITEMS',
        payload: items
    };
};

export const addBreachToBlog = (itemId, callback) => async (dispatch, getState) => {
    try {
        const response = await cyberSecurityData.post('/blog/add', { itemId }, {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

        if (response.data._id) {
            dispatch({
                type: 'REPLACE_MAIN_DASHBOARD_ITEM',
                payload: response.data
            });
            dispatch({
                type: 'ADD_ITEM_TO_BLOG',
                payload: response.data
            });
        }


        if (callback) callback();

    } catch (error) {
        console.log({ error: error.message });
    }
};

export const removeBreachFromBlog = (itemId, callback) => async (dispatch, getState) => {
    try {
        const response = await cyberSecurityData.post('/blog/remove', { itemId }, {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

        if (response.data._id) {
            dispatch({
                type: 'REPLACE_MAIN_DASHBOARD_ITEM',
                payload: response.data
            });
            dispatch({
                type: 'REMOVE_ITEM_FROM_BLOG',
                payload: response.data
            });
        }

        if (callback) callback();

    } catch (error) {
        console.log({ error: error.message });
    }
};

// -----------------------------------------  Filters Actions ------------------------------------------
export const addFilterMainDashboard = (key, value) => (dispatch, getState) => {
    dispatch({
        type: 'ADD_FILTER_MAIN_DASHBOARD',
        payload: { key, value }
    });
    if (key === 'search') {
        //1. saving the current time filter in prevTime state
        if (!getState().dashboard_Main.prevTime) {
            const currentTimeFilter = getState().dashboard_Main.filters.time;
            dispatch({
                type: 'SET_PREV_TIME_MAIN_DASHBOARD',
                payload: currentTimeFilter
            });
        }

        //2. setting time filter to all
        dispatch({
            type: 'ADD_FILTER_MAIN_DASHBOARD',
            payload: { key: 'time', value: 'All' }
        });
    }
};
export const removeFilterMainDashboard = (key) => (dispatch, getState) => {
    //1. if the filter to remove is search, will apply the saved prev time filter, and also reseting the prevTime state value
    const prevTime = getState().dashboard_Main.prevTime;
    if (key === 'search' && prevTime) {
        dispatch({
            type: 'ADD_FILTER_MAIN_DASHBOARD',
            payload: { key: 'time', value: prevTime }
        });
        dispatch({
            type: 'SET_PREV_TIME_MAIN_DASHBOARD',
            payload: ''
        });
    }
    dispatch({
        type: 'DELETE_FILTER_MAIN_DASHBOARD',
        payload: key
    });
};
export const resetFiltersMainDashboard = () => (dispatch, getState) => {
    const prevTime = getState().dashboard_Main.prevTime;
    const search = getState().dashboard_Main.filters.search;

    dispatch({
        type: 'SET_SELECTED_FILTERS_PRESET_MAIN_DASHBOARD',
        payload: ''
    });
    dispatch({
        type: 'RESET_FILTERS_MAIN_DASHBOARD',
    });

    //1. if the filters contains search, will apply the saved prev time filter, and also reseting the prevTime state value
    if (search && prevTime) {
        dispatch({
            type: 'ADD_FILTER_MAIN_DASHBOARD',
            payload: { key: 'time', value: prevTime }
        });
        dispatch({
            type: 'SET_PREV_TIME_MAIN_DASHBOARD',
            payload: ''
        });
    }
};

export const setFiltersMainDashboard = (filters) => {
    return {
        type: 'SET_FILTERS_MAIN_DASHBOARD',
        payload: filters
    };
};

export const setSelectedFiltersPresetMainDashboard = (preset) => {
    return {
        type: 'SET_SELECTED_FILTERS_PRESET_MAIN_DASHBOARD',
        payload: preset
    };
};

// -----------------------------------------  Sort Actions ------------------------------------------

export const changeSortOrderMainDashboard = () => {
    return {
        type: 'CHANGE_SORT_ORDER_MAIN_DASHBOARD',
    };
};

export const changeThreatsSortByMainDashboard = (sortBy) => {
    return {
        type: 'CHANGE_THREATS_SORT_BY_MAIN_DASHBOARD',
        payload: sortBy
    };
};

export const changeBreachesSortByMainDashboard = (sortBy) => {
    return {
        type: 'CHANGE_BREACHES_SORT_BY_MAIN_DASHBOARD',
        payload: sortBy
    };
};

export const changeMalwareSortByMainDashboard = (sortBy) => {
    return {
        type: 'CHANGE_MALWARE_SORT_BY_MAIN_DASHBOARD',
        payload: sortBy
    };
};

export const changeActorsSortByMainDashboard = (sortBy) => {
    return {
        type: 'CHANGE_ACTORS_SORT_BY_MAIN_DASHBOARD',
        payload: sortBy
    };
};

export const changeCVEsSortByMainDashboard = (sortBy) => {
    return {
        type: 'CHANGE_CVES_SORT_BY_MAIN_DASHBOARD',
        payload: sortBy
    };
};


// -----------------------------------------  Favorites Actions ------------------------------------------

export const toggleFavoritesMainDashboard = () => {
    return {
        type: 'TOGGLE_FAVORITES_MAIN_DASHBOARD',
    };
};

// -----------------------------------------  count update Actions ------------------------------------------

export const updateCountersMainDashboard = (count) => { //count => {threats: number, breaches: number}
    return {
        type: 'ITEMS_COUNT_MAIN_DASHBOARD',
        payload: count
    };
};

// -----------------------------------------  Mitre Table Item Actions ------------------------------------------

export const setMitreTableItemId = (itemId) => { //count => {threats: number, breaches: number}
    return {
        type: 'MITRE_ITEM_MAIN_DASHBOARD',
        payload: itemId
    };
};

// -----------------------------------------  Mitre Table Active Item Actions ------------------------------------------

export const setMitreTableActiveItem = (itemId) => { //count => {threats: number, breaches: number}
    return {
        type: 'ACTIVE_MITRE_ITEM_MAIN_DASHBOARD',
        payload: itemId
    };
};

// -----------------------------------------  Authors Dropdown in the breaches Dashboard ------------------------------------------

export const loadDetectionsAuthors = (callback) => async (dispatch, getState) => {
    try {
        const response = await cyberSecurityData.get('/detections/authors', {
            headers: {
                Authorization: `Bearer ${getState().accessToken}`
            }
        });

        dispatch({
            type: 'SET_DETECTIONS_AUTHORS',
            payload: response.data
        });

        if (callback) callback();

    } catch (error) {
        console.log({ error: error.message });
    }
};

export const setChainAuthor = (author) => {
    return {
        type: 'SET_CHAIN_AUTHOR',
        payload: author
    };
};

// -----------------------------------------  Mitre Table Active Item Actions ------------------------------------------

export const setCVESDashboardColors = (boolean) => {
    return {
        type: 'SET_CVES_DASHBOARD_COLORS',
        payload: boolean
    };
};
