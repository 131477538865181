import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

//reducers
import autocompleteReducers from './autocompleteReducers';
import searchTermReducers from './searchTermReducers';
import selectedItemReducers from './selectedItemReducers';
import searchSideFiltersReducers from './filters/searchSideFiltersReducers';
import selectedFiltersReducers from './filters/selectedFiltersReducers';
import searchRelatedBreachesReducers from './results/searchRelatedBreachesReducers';
import searchRelatedIOCsReducers from './results/searchRelatedIOCsReducers';
import searchRelatedTTPsReducers from './results/searchRelatedTTPsReducers';
import searchRelatedProceduresReducers from './results/searchRelatedProceduresReducers';
import loadingSearchResultsReducers from './loading/loadingSearchResultsReducers';
import searchTopBreachesHeaderReducers from './results/searchTopBreachesHeaderReducers';
import searchWithTTPsReducers from './searchWithTTPsReducers';


export default combineReducers({
    autocompleteData: autocompleteReducers,
    topBreachesHeader: searchTopBreachesHeaderReducers,
    withTTPs: searchWithTTPsReducers,
    searchTerm: searchTermReducers,
    selectedItem: selectedItemReducers,
    filters: searchSideFiltersReducers,
    selectedFilters: selectedFiltersReducers,
    relatedBreaches: searchRelatedBreachesReducers,
    relatedIOCs: searchRelatedIOCsReducers,
    relatedTTPs: searchRelatedTTPsReducers,
    relatedProcedures: searchRelatedProceduresReducers,
    loading: loadingSearchResultsReducers,
});