/* eslint-disable import/no-anonymous-default-export */

const initialState = {
};

export default (state = initialState, action) => {
  if (['SET_ENTERPRISE', 'EMAIL_LOGIN'].includes(action.type)) {
    return action.payload?.enterprise ? action.payload.enterprise : {};
  }

  if (action.type === 'UPDATE_ENTERPRISE') {
    return action.payload;
  }

  if (action.type === 'LOGOUT_USER') {
    return initialState;
  }



  return state;
};