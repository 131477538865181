/* eslint-disable import/no-anonymous-default-export */

export default (state = null, action) => {

  if (action.type === 'ADD_CARD') {
    return [...state, action.payload._id];
  }

  if (action.type === 'DUPLICATE_CARD') {
    return action.payload.groupCardsOrder;
  }

  if (action.type === 'LOAD_CARDS') {
    return action.payload.pref.groupCardsOrder[action.payload.pref.activeGroup];
  }

  if (action.type === 'DELETE_CARD') {
    return state.filter(el => el !== action.payload);
  }

  if (action.type === 'SWAP_CARDS') {
    return action.payload;
  }

  if (action.type === 'LOGOUT_USER') {
    return [];
  }

  return state;
};
