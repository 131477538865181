/* eslint-disable import/no-anonymous-default-export */

const initialState = false;

export default (state = initialState, action) => {
    if (action.type === 'SET_IS_TESTING') {
        return action.payload;
    }

    if (action.type === 'LOGOUT') {
        return initialState;
    }

    return state;
};