/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = {}, action) => {
    switch (action.type) {

        case 'FETCH_ITEMS':
            //'FETCH_ITEMS' actions returns an array, it will be converted to object mapped to id for easy updating and working with
            return _.mapKeys(action.payload.items, '_id');

        case 'LOAD_MORE':
            //'FETCH_ITEMS' actions returns an array, it will be converted to object mapped to id for easy updating and working with
            const newItems = _.mapKeys(action.payload.items, '_id');
            return { ...state, ...newItems };

        case 'GET_ITEM_RELATIONS':
            let currentItem = state[action.payload.itemId];
            return { ...state, [action.payload.itemId]: { ...currentItem, relatedItems: action.payload.relatedItems } }

        case 'UPDATE_ITEM':
            return { ...state, [action.payload._id]: action.payload }

        case 'CHANGE_TO_BREACH':
            return { ...state, [action.payload._id]: action.payload }

        case 'UPDATE_ITEM_DATA':
            return { ...state, [action.payload._id]: action.payload }

        case 'INCREMENT_VIEWS_COUNT':
            return { ...state, [action.payload._id]: action.payload }

        case 'LOGOUT_USER':
            return {}

        default:
            return state;
    }
}