import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import CyberSecurity from '../../../apis/cyberSecurityData';
import history from '../../../history';

const VerifyEmailModal = ({ onModalHide, registeredEmail }) => {
  console.log(registeredEmail);
  //local
  const [message, setMessage] = useState('');

  const onResendEmailClick = async () => {
    try {
      setMessage('');
      const response = await CyberSecurity.post('/resendactivation', { email: registeredEmail });
      if (response.data === 'Email sent') {
        return setMessage('Email sent');
      }
      if (response.data === 'User is activated') {
        return setMessage('Your account has already been activated, please login in now.');
      }
    } catch (error) {
      setMessage('Something went wrong, please try again.')
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col pb-3">

        <h2 className="px-4 pb-2">Verify your email</h2>
        <p>You will need to verify your email to complete registration</p>
        <div className="text-center py-4"><img src={require('../../../images/undraw_Mailbox_re_dvds.svg').default} alt="logo" className="img-fluid" style={{ width: '200px' }} /></div>
        <p className="px-4 pt-4">An email has been sent to <a href="mailto:anna.goncharenko@gmail.com" className="text-bold text-link text-blue">{registeredEmail}</a> with a link to verify your account. If you have not received the email after few minutes, please check your spam folder</p>

        <div className="py-4 d-flex justify-content-center">
          <Button variant="primary" onClick={onResendEmailClick} className="mr-3">Resend email</Button>
          {/* <Button variant="outline-primary" onClick={() => history.push('/#contactUS')}>Contact support</Button> */}
          <a href="/#contactUS" target="_blank"><Button variant="outline-primary">Contact support</Button></a>
        </div>
        {message && <p className={message === 'Email sent' || message === 'Your account has already been activated, please login in now.' ? 'text-green' : 'text-red'}>{message}</p>}
      </div>
    </div>
  )
}

export default VerifyEmailModal
