import { combineReducers } from 'redux';

import assets from './assetsItemsReducer';
import filters from './assetsFiltersReducer';
import sortBy from './assetsSortByReducer';
import loading from './assetsLoadingReducer';
import countData from './assetsCountDataReducer';
import page from './assetsPageReducer';

export default combineReducers({
  //Custom User Dashboard
  page,
  assets,
  filters,
  sortBy,
  loading,
  countData,
});