/* eslint-disable import/no-anonymous-default-export */

const initialState = 0;

export default (state = initialState, action) => {
    if (action.type === 'LOAD_DETECTIONS_LIST') {
        return action.payload.totalCount ? action.payload.totalCount : 0;
    }

    if (action.type === 'SET_DETECTIONS_LIST_TOTAL_COUNT') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER' || action.type === 'RESET_DETECTIONS_LIST_TOTAL_COUNT') {
        return initialState;
    }

    return state;
};