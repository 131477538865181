import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
//imported comps
import LoginForm from '../../Forms/LoginForm';
import SignupBasicInfo from '../../Forms/SignupBasicInfo';
import EnterEmailForm from '../../Pages/UserPages/ForgotPassword/EnterEmailForm';
import VerifyEmailModal from './VerifyEmailModal';
import MFAVerifyForm from '../../Forms/MFAVerifyForm';

const LoginModal = ({ showLoginModal, setShowLoginModal, action, email }) => {
  //comp local state
  const [activeForm, setActiveForm] = useState(action ? action : 'login'); //login || signup || reset-password || confirm || mfa-verify
  // const [activeForm, setActiveForm] = useState('confirm') //login || signup || reset-password || confirm
  const [registeredEmail, setRegisteredEmail] = useState('Test@test.com');
  const darkMode = useSelector(state => state.darkMode);

  useEffect(() => {
    if (action) setActiveForm(action);
  }, [action]);

  const onModalHide = () => {
    setShowLoginModal(false);
    setTimeout(() => {
      setActiveForm(action ? action : 'login');
      // setActiveForm('confirm')
    }, 500);
  };

  const renderLogo = () => {
    if (darkMode === true) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" className="img-fluid" style={{ maxWidth: "60px" }} />;
    if (darkMode === false) return <img src={require('../../../images/TruKno-Logo-New.svg').default} alt="logo" className="img-fluid" style={{ maxWidth: "60px" }} />;
  };

  return (
    <Modal show={showLoginModal} onHide={onModalHide} centered dialogClassName="login-signup" className="login">

      <Modal.Header closeButton>
        <Modal.Title className="w-100">

        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center">

        {activeForm === 'login' &&
          <div>
            <div className="text-center my-4">
              {renderLogo()}
              <div className="text-center text-lg text-semibold pt-4"><span>Highly-Curated Cyber Threat Intelligence</span></div>
            </div>
            <LoginForm setShowLoginModal={setShowLoginModal} setActiveForm={setActiveForm} setRegisteredEmail={setRegisteredEmail} /></div>}
        {activeForm === 'signup' &&
          <div>
            <div className="text-center my-4">
              {renderLogo()}
              <div className="text-center text-lg text-semibold pt-4"><span>Highly-Curated Cyber Threat Intelligence</span></div>
            </div>
            <SignupBasicInfo setShowLoginModal={setShowLoginModal} setActiveForm={setActiveForm} email={email} setRegisteredEmail={setRegisteredEmail} />
          </div>
        }
        {activeForm === 'reset-password' &&
          <div>
            <div className="text-center my-4">
              {renderLogo()}
            </div>
            <EnterEmailForm setShowLoginModal={setShowLoginModal} setActiveForm={setActiveForm} />
          </div>
        }

        {activeForm === 'mfa-verify' &&
          <MFAVerifyForm setShowLoginModal={setShowLoginModal} setActiveForm={setActiveForm} setRegisteredEmail={setRegisteredEmail} />
        }
        {registeredEmail && activeForm === 'confirm' && <VerifyEmailModal onModalHide={onModalHide} registeredEmail={registeredEmail} />}

      </Modal.Body>
    </Modal >
  );
};

export default LoginModal;
