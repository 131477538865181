import { combineReducers } from 'redux';

import tenableCVEs from './tenableCVEsReducer';
import tenableFilters from './tenableFiltersReducer';
import sortBy from './tenableSortByReducer';
import loading from './tenableLoadingReducer';
import countData from './tenableCounrDataReducer';
import tenablePageReducer from './tenablePageReducer';

export default combineReducers({
  //Custom User Dashboard
  page: tenablePageReducer,
  cves: tenableCVEs,
  filters: tenableFilters,
  sortBy,
  loading,
  countData,
});