import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Button, Navbar, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import history from '../../../history';
import animateScrollTo from 'animated-scroll-to';
//actions
import { logoutUser } from '../../../actions/userActions';
import { setMainDashboardCategory } from '../../../actions/Dashboard/mainDashboardActions';
//comps
import LoginModal from '../../Modals/LoginModal/LoginModal';
import RequestAccessModal from '../../Modals/RequestAccessModal/RequestAccessModal';
//local images

import { Icon } from '@iconify/react';
import menuOutlined from '@iconify/icons-ant-design/menu-outlined';

import logo from '../../../images/trukno-logo-full.svg';

const LandingHeader = ({ isTitleVisible }) => {
  //redux store state
  const currentUser = useSelector(state => state.currentUser);
  const enterprise = useSelector(state => state.enterprise);
  const dispatch = useDispatch();
  //comp local state
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    setIsScrolling(true);
  };

  const onContactClick = () => {
    animateScrollTo(document.querySelector('#contact-us'));
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (history.location?.hash?.toLowerCase().includes('contact-us')) onContactClick();
  }, []);

  const onAccessClick = () => {
    // setShowRequestAccessModal(true);
    history.push('/dashboard/news');
  };

  const onLoginClick = () => {
    setShowLoginModal(true);
  };

  const renderLoginButton = () => {
    if (currentUser._id) return <span className="btn btn-outline-light" onClick={() => dispatch(logoutUser())}>Logout</span>;
    return <span className="btn-simple-text-light" onClick={onLoginClick}>Login</span>;
  };

  const onGetStartedClick = () => {
    isMobile ? history.push('/dashboard/news') : history.push('/dashboard/main');
  };

  const getRoute = () => {
    // if (['professionalMonthly', 'professionalYearly', 'trial'].includes(currentUser.plan)) return '/dashboard/main';
    if (enterprise?.active === true) return '/dashboard/main';
    return '/dashboard/news';
  };

  const onLogoClick = () => {
    if (enterprise._id) {
      dispatch(setMainDashboardCategory('breaches'));
      history.push('/dashboard/main');
      return;
    }
    history.push('/dashboard/news');
  };

  return (
    <header className={`fixed-top ${isTitleVisible ? 'sticky' : ''}`}>
      <div className="container">
        <Navbar expand="lg" variant="" className="d-flex justify-content-between p-0">
          <Navbar.Brand><img src={logo} alt="logo" className="img-fluid pointer" onClick={onLogoClick} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"><Icon icon={menuOutlined} color="#fff" /> </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Nav>
              <Nav.Link className={history.location.pathname === '/' ? '' : ''} onClick={() => history.push(getRoute())}>Dashboard</Nav.Link>
              <Nav.Link className={history.location.pathname === '/' ? 'active' : ''} onClick={() => history.push('/')}>About</Nav.Link>
              <Nav.Link className={history.location.pathname === '/pricing' ? 'active' : ''} onClick={() => history.push('/pricing')}>Pricing</Nav.Link>
              <Nav.Link className={history.location.pathname === '/resources' ? 'active' : ''} onClick={() => history.push('/resources')}>Resources</Nav.Link>
              <Nav.Link className={history.location.pathname === '/#contactUS' ? 'active' : ''}
                onClick={() => {
                  if (history.location.pathname !== '/') return history.push('/#contact-us');
                  onContactClick();
                }}
              >Contact</Nav.Link>
              <Nav.Link className={history.location.pathname === '/blog' ? 'active' : ''} onClick={() => history.push('/blog')}>Blog</Nav.Link>
            </Nav>

            <div className="d-flex flex-column justify-content-center align-items-center d-lg-none mt-4 pt-3 border-top">
              {renderLoginButton()}
              {!currentUser._id && <span className="btn btn-outline-light justify-content-center ml-lg-3" onClick={onAccessClick}>Access Now</span>}
            </div>

          </Navbar.Collapse>

          <div className="d-none d-lg-flex align-items-center">
            {renderLoginButton()}
            {!currentUser._id && <span className="btn btn-outline-light ml-3" onClick={onAccessClick}>Access Now</span>}
          </div>
        </Navbar>
      </div>
      <LoginModal showLoginModal={showLoginModal} setShowLoginModal={setShowLoginModal} action='login' />
      <LoginModal showLoginModal={showSignupModal} setShowLoginModal={setShowSignupModal} action='signup' />
      <RequestAccessModal showRequestAccessModal={showRequestAccessModal} setShowRequestAccessModal={setShowRequestAccessModal} />
    </header>
  );
};

export default LandingHeader;
