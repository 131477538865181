/* eslint-disable import/no-anonymous-default-export */

export default (state = -1, action) => { //-1 or 1

    if (action.type === 'SET_BREACHES_LIST_SORT_ORDER') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return -1
    }

    return state
}