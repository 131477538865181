/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

export default (state = { time: '30 Days' }, action) => {

  if (action.type === 'ADD_FILTER_QUALYS') {
    return { ...state, [action.payload.key]: action.payload.value };  // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
  }

  if (action.type === 'DELETE_FILTER_QUALYS') {
    const newFilters = _.omit(state, action.payload);
    return { ...newFilters };
  }

  if (action.type === 'RESET_FILTERS_QUALYS') {
    return {};  // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
  }

  if (action.type === 'SET_FILTERS_QUALYS') {
    return { ...action.payload }; // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return {};
  }

  return state;
};