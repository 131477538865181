/* eslint-disable import/no-anonymous-default-export */

const initialState = true;

export default (state = initialState, action) => {
    if (
        [
            'LOAD_DETECTIONS_LIST',
        ].includes(action.type)
    ) {
        return false;
    }

    if (
        [
            'ADD_DETECTION_FILTER',
            'DELETE_DETECTION_FILTER',
            'RESET_DETECTION_FILTER',
            'SET_DETECTIONS_SORT_BY',
        ].includes(action.type)
    ) {
        return true;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};