/* eslint-disable import/no-anonymous-default-export */

export default (state = true, action) => {
    if (action.type === 'SET_SEARCH_PAGE_WITH_TTPS') {
        return action.payload;
    }

    if (['LOGOUT_USER'].includes(action.type)) {
        return true;
    }

    return state;
};