/* eslint-disable import/no-anonymous-default-export */

export default (state = 'Latest Breach', action) => { //'Latest Breach', 'Breach Count'

    if (action.type === 'SET_THREATS_LIST_SORT_BY') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return 'Latest Breach'
    }

    return state
}