/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';
const initialState = {
    page: 1,
    view: 'table', // table | tiles
    category: 'breaches', // will be either threats or breaches
    filters: { time: '30 Days' },
    malwareFilter: 'malwareType', // one of the 'malwareName' or 'malwareType'
    aggregateTTPs: false,
    prevTime: '',
    selectedFiltersPreset: '',
    favorites: false,
    sortOrder: -1,
    threatsSortBy: 'latestBreach',
    breachesSortBy: 'exactDate',
    malwareSortBy: 'date',
    actorsSortBy: 'date',
    cvesSortBy: 'articles',
    items: [],
    firstLoad: false,
    threatsCount: '',
    breachesCount: '',
    malwareCount: '',
    actorsCount: '',
    mitreTableItemId: '',
    mitreTableActiveItem: '', //id of the active item
    loading: true,
    cvesColors: false,
    searchedData: {},
    searchType: false,
    searchedDataLoader: false,
    searchedDataError: '',
    detectionsAuthors: [],
};

export default (state = initialState, action) => {

    // -----------------------------------------  first load Actions ---------------------------------------------
    if (action.type === 'SET_FIRST_LOAD_MAIN_DASHBOARD') {
        return { ...state, firstLoad: true };
    }

    // -----------------------------------------  VIEW Actions ---------------------------------------------
    if (action.type === 'SET_MAIN_DASHBOARD_VIEW') {
        return { ...state, view: action.payload || 'table' };
    }

    // -----------------------------------------  Page Actions ---------------------------------------------
    if (action.type === 'INCREMENT_MAIN_DASHBOARD_PAGE') {
        return { ...state, page: state.page + 1, loading: true };
    }

    if (action.type === 'RESET_MAIN_DASHBOARD_PAGE') {
        return { ...state, page: 1 };
    }

    // -----------------------------------------  Category Actions ---------------------------------------------
    if (action.type === 'SET_MAIN_DASHBOARD_CATEGORY') {
        // let currentFilters = { ...state.filters };

        // if (action.payload === 'threats') {
        //     const threatsFilters = ['industry', 'technology', 'search', 'time'];
        //     Object.keys(currentFilters).forEach(key => {
        //         if (!threatsFilters.includes(key)) currentFilters = _.omit(currentFilters, key);
        //     })
        // } else if (action.payload === 'breaches') {
        //     const breachesFilters = ['industry', 'technology', 'search', 'time'];
        //     Object.keys(currentFilters).forEach(key => {
        //         if (!breachesFilters.includes(key)) currentFilters = _.omit(currentFilters, key);
        //     })
        // }

        // if (!currentFilters.time) currentFilters.time = 'All';

        return { ...state, items: [], category: action.payload, page: 1, sortOrder: -1, view: ['actors', 'malware'].includes(action.payload) ? 'tiles' : 'table', loading: true }; //reseting page and filters with each category change
    }

    // -----------------------------------------  Items Actions ---------------------------------------------
    if (action.type === 'SET_MAIN_DASHBOARD_ITEMS') {
        let items = action.payload;
        return { ...state, items: items, page: 1, loading: false }; // Note: added page: 1 so whenever items are set => the page will be resest to 1
    }

    if (action.type === 'LOAD_MORE_MAIN_DASHBOARD_ITEMS') {
        //making sure that the coming items are not in the existing items in the store
        const existIds = state.items.map(el => el._id);
        const filtersItems = action.payload.filter(el => !existIds.includes(el._id));

        return { ...state, items: [...state.items, ...filtersItems], loading: false };
    }

    if (action.type === 'REPLACE_MAIN_DASHBOARD_ITEM') {
        return { ...state, items: state.items.map(el => el._id === action.payload._id ? action.payload : el) };
    }

    // -----------------------------------------  Filters Actions ---------------------------------------------

    if (action.type === 'ADD_FILTER_MAIN_DASHBOARD') {
        return { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.value }, page: 1, loading: true };  // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
    }

    if (action.type === 'DELETE_FILTER_MAIN_DASHBOARD') {
        const newFilters = _.omit(state.filters, action.payload);
        return { ...state, filters: newFilters, loading: true };
    }

    if (action.type === 'RESET_FILTERS_MAIN_DASHBOARD') {
        return { ...state, filters: { time: '30 Days' }, page: 1, loading: true };  // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
    }

    if (action.type === 'SET_FILTERS_MAIN_DASHBOARD') {
        return { ...state, filters: action.payload, page: 1, loading: true }; // Note: added page: 1 so whenever a filter is added => the page will be resest to 1
    }

    if (action.type === 'SET_SELECTED_FILTERS_PRESET_MAIN_DASHBOARD') {
        return { ...state, selectedFiltersPreset: action.payload, loading: true };
    }

    if (action.type === 'SET_PREV_TIME_MAIN_DASHBOARD') {
        return { ...state, prevTime: action.payload, loading: true };
    }

    // -----------------------------------------  Aggregate TTPs Actions --------------------------------

    if (action.type === 'SET_MAIN_DASHBOARD_AGGREGATE_TTPS') {
        return { ...state, aggregateTTPs: action.payload, page: 1, loading: true };
    }

    // -----------------------------------------  Malware filter type selection Actions --------------------------------

    if (action.type === 'SET_MAIN_DASHBOARD_MALWARE_FILTER_SELECTION') {
        return { ...state, malwareFilter: action.payload };
    }

    // -----------------------------------------  Sort Actions ------------------------------------------

    if (action.type === 'CHANGE_SORT_ORDER_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, sortOrder: state.sortOrder * -1 };
    }

    if (action.type === 'CHANGE_THREATS_SORT_BY_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, threatsSortBy: action.payload };
    }

    if (action.type === 'CHANGE_BREACHES_SORT_BY_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, breachesSortBy: action.payload };
    }

    if (action.type === 'CHANGE_MALWARE_SORT_BY_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, malwareSortBy: action.payload };
    }

    if (action.type === 'CHANGE_ACTORS_SORT_BY_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, actorsSortBy: action.payload };
    }

    if (action.type === 'CHANGE_CVES_SORT_BY_MAIN_DASHBOARD') {
        return { ...state, items: [], page: 1, cvesSortBy: action.payload };
    }

    // -----------------------------------------  Sort Actions ------------------------------------------
    if (action.type === 'TOGGLE_FAVORITES_MAIN_DASHBOARD') {
        return { ...state, favorites: !state.favorites, page: 1 };
    }

    // -----------------------------------------  count update Actions ------------------------------------------
    if (action.type === 'ITEMS_COUNT_MAIN_DASHBOARD') {
        return { ...state, threatsCount: action.payload.threats, breachesCount: action.payload.breaches, malwareCount: action.payload.malware, actorsCount: action.payload.actors, cvesCount: action.payload.cves };
    }

    // -----------------------------------------  Mitre Table Item Actions ------------------------------------------
    if (action.type === 'MITRE_ITEM_MAIN_DASHBOARD') {
        return { ...state, mitreTableItemId: action.payload };
    }

    // -----------------------------------------  Mitre Table Active Item Actions ------------------------------------------
    if (action.type === 'ACTIVE_MITRE_ITEM_MAIN_DASHBOARD') {
        return { ...state, mitreTableActiveItem: action.payload };
    }

    // -----------------------------------------  CVES Colors ------------------------------------------
    if (action.type === 'SET_CVES_DASHBOARD_COLORS') {
        return { ...state, cvesColors: action.payload };
    }

    // -----------------------------------------  EDR SCAN RESULTS ------------------------------------------
    if (action.type === 'SET_DETECTIONS_AUTHORS') {
        return { ...state, detectionsAuthors: action.payload };
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};