/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const defaultState = {
  page: 1,
  filters: {},
  channels: [],
  activeChannel: '',
  threads: {},
  totalCount: 0
};

export default (state = defaultState, action) => {

  // -------------------------- FILTERS ACTIONS -------------------------------
  if (action.type === 'ADD_PERSONAL_BOARD_FILTER') {
    const newFilters = { ...state.filters, [action.payload.key]: action.payload.value };
    return { ...state, filters: newFilters, page: 1 }
  }

  if (action.type === 'REMOVE_PERSONAL_BOARD_FILTER') {
    const newFilters = _.omit(state.filters, action.payload);
    return { ...state, filters: newFilters, page: 1 }
  }

  if (action.type === 'RESET_PERSONAL_BOARD_FILTER') {
    return { ...state, filters: {}, page: 1 }
  }

  // -------------------------- PAGE ACTIONS -------------------------------
  if (action.type === 'PERSONAL_BOARD_NEXT_PAGE') {
    return { ...state, page: state.page + 1 }
  }

  // -------------------------- CHANNELS ACTIONS -------------------------------
  if (action.type === 'LOAD_PERSONAL_CHANNELS') {
    return { ...state, channels: action.payload }
  }

  if (action.type === 'SET_PERSONAL_ACTIVE_CHANNEL') {
    return { ...state, activeChannel: action.payload, page: 1 }
  }

  if (action.type === 'DELETE_PERSONAL_CHANNEL') {
    return { ...state, channels: state.channels.filter(el => el._id !== action.payload) }
  }

  // -------------------------- Threads ACTIONS -------------------------------
  if (action.type === 'SET_PERSONAL_CHANNEL_THREADS') {
    const newThreads = { ...state.threads, [action.payload.channel]: { items: action.payload.items, totalCount: action.payload.totalCount, page: action.payload.page } }
    return { ...state, threads: newThreads }
    // if (state.page === 1) {
    //   return { ...state, threads: action.payload }
    // } else {
    //   return { ...state, threads: [...action.payload, ...state.threads] }
    // }
  }

  if (action.type === 'LOAD_MORE_PERSONAL_CHANNEL_THREADS') {
    const prevItems = state.threads[action.payload.channel].items;
    const newItems = [...action.payload.items, ...prevItems];
    const newThreads = { ...state.threads, [action.payload.channel]: { items: newItems, totalCount: action.payload.totalCount, page: action.payload.page } }
    return { ...state, threads: newThreads }
  }

  if (action.type === 'PERSONAL_THREAD_ADDED') {
    const activeChannelId = state.activeChannel._id
    const currentThreads = state.threads[activeChannelId].items;
    const newItems = [...currentThreads, action.payload]
    return { ...state, threads: { ...state.threads, [activeChannelId]: { ...state.threads[activeChannelId], items: newItems, totalCount: state.threads[activeChannelId].totalCount + 1 } } }
  }

  if (action.type === 'PERSONAL_THREAD_DELETE') {
    const threadId = action.payload;
    const activeChannelId = state.activeChannel._id
    const currentThreads = state.threads[activeChannelId].items;
    const newItems = currentThreads.filter(el => el._id !== threadId);
    return { ...state, threads: { ...state.threads, [activeChannelId]: { ...state.threads[activeChannelId], items: newItems, totalCount: state.threads[activeChannelId].totalCount - 1 } } }
  }

  if (action.type === 'PERSONAL_THREAD_EDITED') {
    const editedThread = action.payload;
    const activeChannelId = state.activeChannel._id
    const currentThreads = state.threads[activeChannelId].items;
    const newItems = currentThreads.map(el => el._id === editedThread._id ? editedThread : el);
    return { ...state, threads: { ...state.threads, [activeChannelId]: { ...state.threads[activeChannelId], items: newItems, totalCount: state.threads[activeChannelId].totalCount } } }
  }

  if (action.type === 'PERSONAL_THREAD_MOVE') {
    const type = action.payload.action;
    const threadId = action.payload.threadId;
    const activeChannelId = state.activeChannel._id;

    let newItems = {}, newCount;

    if (type === 'Move') {
      newItems = state.threads[activeChannelId].items.filter(el => el._id !== threadId);
      newCount = state.threads[activeChannelId].totalCount - 1
    }
    return { ...state, threads: { ...state.threads, [activeChannelId]: { items: newItems, totalCount: newCount } } }
  }

  // -------------------------- NOTES ACTIONS -------------------------------

  if (action.type === 'PERSONAL_NOTE_TO_THREAD_ADDED') {
    const addedNote = action.payload;
    const channelId = addedNote.channelId;
    const channelThreads = state.threads[channelId].items;
    const thread = channelThreads.find(el => el._id === addedNote.threadId);
    const newNotes = [...thread.notes, addedNote];
    thread.notes = newNotes;

    const newThreads = channelThreads.map(el => el._id === thread._id ? thread : el);
    return { ...state, threads: { ...state.threads, [channelId]: { ...state.threads[channelId], items: newThreads } } }
  }

  if (action.type === 'EDIT_PERSONAL_THREAD_NOTE') {
    const editedNote = action.payload;
    const channelId = editedNote.channelId;
    const channelThreads = state.threads[channelId].items;
    const thread = channelThreads.find(el => el._id === editedNote.threadId);
    const newNotes = thread.notes.map(el => el._id === editedNote._id ? editedNote : el);
    thread.notes = newNotes;

    const newThreads = channelThreads.map(el => el._id === thread._id ? thread : el);
    return { ...state, threads: { ...state.threads, [channelId]: { ...state.threads[channelId], items: newThreads } } }

  }

  if (action.type === 'DELETE_PERSONAL_THREAD_NOTE') {
    const { noteId, threadId } = action.payload;

    const channelId = state.activeChannel._id;
    const channelThreads = state.threads[channelId].items;
    const thread = channelThreads.find(el => el._id === threadId);
    const newNotes = thread.notes.filter(el => el._id !== noteId);
    thread.notes = newNotes;

    const newThreads = channelThreads.map(el => el._id === thread._id ? thread : el);
    return { ...state, threads: { ...state.threads, [channelId]: { ...state.threads[channelId], items: newThreads } } }
  }

  if (action.type === 'LOGOUT_USER') {
    return defaultState
  }

  return state
}
//EMAIL_LOGIN LINKEDIN_LOGIN