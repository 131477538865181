import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { isEmail } from 'validator';
import CyberSecurity from '../../../apis/cyberSecurityData';
import ReCAPTCHA from "react-google-recaptcha";

const regexEmail = /^[ A-Za-z0-9_.@&?!',-/()]*$/;

const JoinUs = () => {
  //comp local state
  const [confirm, setConfirm] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  useEffect(() => {
    if (email) setShowRecaptcha(true);
    else setShowRecaptcha(false);
  }, [email]);


  const onSubmitClick = async () => {

    if (!email || !isEmail(email)) return setError('Please, enter a valid email');

    const validatedEmail = email.match(regexEmail);
    if (!validatedEmail) return setError('Please, enter a valid email');

    if (!recaptcha) return setError('reCaptcha validation error, please try again');

    setError('');

    const response = await CyberSecurity.post('/joinus', { email, recaptcha });
    if (response.data.error) return setError("Something went wrong");

    setError('');

    setConfirm(true);

    //reset Form
    setEmail('');

    setTimeout(() => {
      setConfirm(false);
    }, 3000);
  };


  return (
    <div>
      <Form>
        <Form.Group className="form-group-inline" controlId="formBasicEmail">
          <Form.Control
            type="email"
            className="form-control--rounded"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button variant="primary" className="btn-gradient-red btn-gradient-red--rounded" onClick={onSubmitClick}>
            <span>Submit</span>
          </Button>

        </Form.Group>

      </Form>

      {showRecaptcha && <ReCAPTCHA
        sitekey="6Lc0_uEUAAAAAE29L9BpPI5PZMN6q2J67R4SN9Hj"
        onChange={setRecaptcha}
      />}
      {error && <div className="error text-left mb-0" style={{ color: '#EA5454' }}>{error}</div>}
      {confirm && <div className="error text-left mb-0" style={{ color: 'green' }}>Message sent, Thank you</div>}
    </div>
  );
};

export default JoinUs

