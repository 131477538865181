/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    activeTab: 'rule',
    rule: {},
    edrScan: {},
    scanning: true,
    chains: [],
    yamlCode: '',
};

export default (state = initialState, action) => {
    if (action.type === 'LOAD_RULE_DATA') {
        return { ...state, rule: action.payload };
    }

    if (action.type === 'LOAD_RULE_EDR_SCAN') {
        return { ...state, edrScan: action.payload, scanning: false };
    }

    if (action.type === 'SET_EDR_ITEM_PAGE_SCANNING') {
        return { ...state, scanning: action.payload };
    }

    if (action.type === 'SET_ACTIVE_TAB') {
        return { ...state, activeTab: action.payload };
    }

    if (action.type === 'LOAD_RULE_CHAINS') {
        return { ...state, chains: action.payload };
    }

    if (action.type === 'SET_RULE_CODE') {
        return { ...state, yamlCode: action.payload };
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER' || action.type === 'CLEAR_RULE_DATA') {
        return initialState;
    }

    return state;
};