import { VTAugment } from '../../components/Pages/Dashboard/sections/ItemPagesCommonComponents/IOCs/vt-augment';

export const setVTContainer = () => {
  const container = document.querySelector('#vt-augment-container');
  const vtElement = new VTAugment(container, { mode: 'drawer' });

  return {
    type: 'SET_VT_CONTAINER',
    payload: vtElement
  }
}


// import VTAugment from '@virustotal/vt-augment/dist/vt-augment.min';

// export const setVTContainer = () => {

//   console.log(window.VTAugment);

//   const container = document.querySelector('#vt-augment-container');
//   console.log(container);

//   const vtElement = window.VTAugment(container);

//   return {
//     type: 'SET_VT_CONTAINER',
//     payload: {}
//   }
// }
