
/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    items: [],
    page: 1,
    pageView: 'main', //main, manageSites, newManageSites
    itemsView: 'cards', //cards, list, compact
    filters: { time: '3 Days' },
    filtersOptions: {},
    searchExactMatch: false,
    favorites: false,
    sortBy: 'exactDate',
    sortOrder: -1,
    totalCount: '',
    selectedFeedToEdit: '',
    newsFirstLoad: true,
    selectedFeed: '',
    prevTime: ''
}

export default (state = initialState, action) => {

    // -----------------------------------------  Selected feed to edit Actions ---------------------------------------------
    if (action.type === 'SELECTED_FEED_TO_EDIT') {
        return { ...state, selectedFeedToEdit: action.payload }
    }

    // -----------------------------------------  Items Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_ITEMS') {
        return { ...state, items: action.payload }
    }
    if (action.type === 'MORE_NEWS_ITEMS') {
        return { ...state, items: [...state.items, ...action.payload] }
    }

    // -----------------------------------------  Page Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_PAGE') {
        return { ...state, page: action.payload }
    }

    // -----------------------------------------  Filters Actions ---------------------------------------------
    if (action.type === 'ADD_NEWS_FILTER') {
        return { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.value } }
    }

    if (action.type === 'REMOVE_NEWS_FILTER') {
        let newFilter = { ...state.filters };
        delete newFilter[action.payload]

        if (action.payload === 'newTag5') {
            return { ...state, filters: newFilter, sortBy: 'exactDate' }
        } else {
            return { ...state, filters: newFilter }
        }
    }

    if (action.type === 'SET_PREV_TIME_NEWS_PAGE') {
        return { ...state, prevTime: action.payload }
    }

    // -----------------------------------------  Exact Match Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_SEARCH_EXACT_MATCH') {
        return { ...state, searchExactMatch: action.payload }
    }

    // -----------------------------------------  Sort Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_SORT_BY') {
        return { ...state, sortBy: action.payload, page: 1 }
    }

    if (action.type === 'CHANGE_NEWS_SORT_ORDER') {
        return { ...state, sortOrder: state.sortOrder * -1, page: 1 }
    }

    // -----------------------------------------  Page View Actions ---------------------------------------------

    //action to handle what to view in the main view (items list, manage websites)
    if (action.type === 'SET_NEWS_PAGE_VIEW') {
        return { ...state, pageView: action.payload }
    }

    //action to change the items view type (card, list, compact view)
    if (action.type === 'SET_NEWS_PAGE_ITEMS_VIEW') {
        return { ...state, itemsView: action.payload }
    }

    // -----------------------------------------  Total Count Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_PAGE_TOTAL_COUNT') {
        return { ...state, totalCount: action.payload }
    }

    // -----------------------------------------  Sort Actions ------------------------------------------
    if (action.type === 'TOGGLE_FAVORITES_NEWS_PAGE') {
        return { ...state, favorites: !state.favorites, page: 1 }
    }

    // -----------------------------------------  FIRST LOAD Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_PAGE_FIRST_LOAD') {
        return { ...state, newsFirstLoad: false }
    }

    // -----------------------------------------  Selected Feed Actions ---------------------------------------------
    if (action.type === 'SET_NEWS_PAGE_SELECTED_FEED') {
        return { ...state, selectedFeed: action.payload, page: 1 }
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'SET_NEWS_FILTERS_OPTIONS') {
        return { ...state, filtersOptions: action.payload }
    }


    return state
}