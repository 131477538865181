import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import itemsReducer from './itemsReducer';
import filtersReducer from './filtersReducer';
import topFiltersReducer from './topFiltersReducer';
import activeTopFilterReducer from './activeTopFilterReducer';
import sideListReducer from './sideListReducer';
import totalCount from './totalCount';
import currentUserReducer from './currentUserReducer';
import accessTokenReducer from './accessTokenReducer';
import setModalViewReducer from './setModalViewReducer';
import modalTypeReducer from './modalTypeReducer';
import favoritesArrayReducer from './favoritesArrayReducer';
import toggleSearchReducer from './toggleSearchReducer';
import currentPageReducer from './currentPageReducer';
import sortByReducer from './sortByReducer';
import sortOrderReducer from './sortOrderReducer';
import sortOptionsReducer from './sortOptionsReducer';
import toBeSharedReducer from './toBeSharedReducer';
import SearchCountReducer from './SearchCountReducer';
import viewReducer from './viewReducer';
import uniqueIdReducer from './uniqueIdReducer';
import exactMatchReducer from './exactMatchReducer';
import SponsoredItemsReducer from './SponsoredItemsReducer';
import unreadMessagesReducer from './unreadMessagesReducer';
import darkModeReducer from './darkModeReducer';
import subDataReducer from './subDataReducer';
import storyNamesReducer from './storyNamesReducer';
import isTestingReducers from './isTestingReducers';

import commonDataReducer from './commonDataReducer/commonDataReducer';

//Dashboard Common State
import socketReducer from './Dashboard/socketReducer';

//Dashboard Boards Section
import mainDashboardReducer from './Dashboard/MainDashboard/mainDashboardReducer';

//Dashboard Boards Section
import boardsSectionReducer from './Dashboard/Boards/boardsSectionReducer';


//Dashboard Threats Section
import threatsListReducer from './Dashboard/ThreatsSection/threatsListReducer';
import threatsListTotalCount from './Dashboard/ThreatsSection/threatsListTotalCount';
import threatsSectionPage from './Dashboard/ThreatsSection/threatsSectionPage';
import threatsSectionActiveItem from './Dashboard/ThreatsSection/threatsSectionActiveItem';
import threatsListSortBy from './Dashboard/ThreatsSection/threatsListSortBy';
import threatsListSortOrder from './Dashboard/ThreatsSection/threatsListSortOrder';
import threatItemCheck from './Dashboard/ThreatsSection/threatItemCheck';

//Dashboard Breaches Section
import breachesListReducer from './Dashboard/BreachesSection/breachesListReducer';
import breachesListTotalCount from './Dashboard/BreachesSection/breachesListTotalCount';
import breachesSectionPage from './Dashboard/BreachesSection/breachesSectionPage';
import breachesSectionActiveItem from './Dashboard/BreachesSection/breachesSectionActiveItem';
import breachesListSortBy from './Dashboard/BreachesSection/breachesListSortBy';
import breachesListSortOrder from './Dashboard/BreachesSection/breachesListSortOrder';
import breachItemChecking from './Dashboard/BreachesSection/breachItemChecking';

//Dashboard News Section
import newsSectionReducer from './Dashboard/newsSectionReducer';

//Graphs Colors Reducer
import graphsColorsReducer from './Dashboard/graphsColorsReducer';

//personal board
import personalBoardReducer from './Dashboard/PersonalBoardReducer/personalBoardReducer';

//virus total reducer
import virusTotalReducer from './VirusTotalReducer/virusTotalReducer';

//custom dashboard
// import filtersOptionsReducer from './Dashboard/customDashboard/filtersOptionsReducer';
// import cardsReducer from './Dashboard/customDashboard/cardsReducer';
// import cardsOrderReducer from './Dashboard/customDashboard/cardsOrderReducer';
// import cardsItemsReducer from './Dashboard/customDashboard/itemsReducer';
// import templateCardsReducer from './Dashboard/customDashboard/templateCardsReducer';

import cisoReducers from './Dashboard/cisoDashboard/cisoReducers';

//resources page
import resourcesReducer from './resourcesReducer';

//Tenable Reducers
import tenableReducer from './Dashboard/TenableReducers/tenableReducer';

//Qualys CVEs Dashboard Reducers
import qualysReducer from './Dashboard/qualysReducers/qualysReducer';

//Assets Reducers
import assetsIndex from './Dashboard/AssetsReducers/assetsIndex';

//Assets Reducers
import qualysAssetsIndex from './Dashboard/qualysAssetsReducers/qualysAssetsIndex';

//Enterprise Reducers
import enterpriseReducers from './enterpriseReducers/enterpriseReducers';

//Blog Reducers
import blogReducers from './blogReducers/Index';

//search page reducers
import searchPageReducers from './searchPageResducer/searchPageReducers';

//detection rules reducers
import detectionsReducers from './detectionRulesReducers/detectionsReducers';
import edrBackgroundScanReducers from './detectionRulesReducers/edrBackgroundScanReducers';

import cisoDashboardNew from './cisoReducers/cisoReducers';

export default combineReducers({
    //Custom User Dashboard
    isTesting: isTestingReducers,
    cisoDashboard: cisoReducers,
    cisoDashboardNew: cisoDashboardNew,
    searchPage: searchPageReducers,
    detections: detectionsReducers,
    edrBackgroundScan: edrBackgroundScanReducers,
    currentUser: currentUserReducer,
    accessToken: accessTokenReducer,
    subData: subDataReducer,
    items: itemsReducer,
    totalCount: totalCount,
    filters: filtersReducer,
    topFilters: topFiltersReducer,
    activeTopFilter: activeTopFilterReducer,
    sideFilters: sideListReducer,
    form: formReducer,
    modalVisible: setModalViewReducer,
    modalType: modalTypeReducer,
    favoritesArray: favoritesArrayReducer,
    searchVisible: toggleSearchReducer,
    currentPage: currentPageReducer,
    sortBy: sortByReducer,
    sortOrder: sortOrderReducer,
    sortOptions: sortOptionsReducer,
    toBeShared: toBeSharedReducer,
    searchCount: SearchCountReducer,
    view: viewReducer,
    uniqueId: uniqueIdReducer,
    exactMatch: exactMatchReducer,
    sponsoredItems: SponsoredItemsReducer,
    unreadMessages: unreadMessagesReducer,
    darkMode: darkModeReducer,
    storyNames: storyNamesReducer,

    //Dashboard Common reducers
    dashboard_socket: socketReducer,

    //Dashboard Threats Section
    dashboard_threatsList: threatsListReducer,
    dashboard_threatsListTotalCount: threatsListTotalCount,
    dashboard_threatsSectionActiveItem: threatsSectionActiveItem,
    dashboard_threatsListPage: threatsSectionPage,
    dashboard_threatsListSortBy: threatsListSortBy,
    dashboard_threatsListSortOrder: threatsListSortOrder,
    dashboard_threatItemCheck: threatItemCheck,

    //Dashboard Breaches Section
    dashboard_breachesList: breachesListReducer,
    dashboard_breachesListTotalCount: breachesListTotalCount,
    dashboard_breachesSectionActiveItem: breachesSectionActiveItem,
    dashboard_breachesListPage: breachesSectionPage,
    dashboard_breachesListSortBy: breachesListSortBy,
    dashboard_breachesListSortOrder: breachesListSortOrder,
    dashboard_breachItemCheck: breachItemChecking,

    //Dashboard News Section
    dashboard_NewsState: newsSectionReducer,

    //Dashboard Personal Board
    personalBoard: personalBoardReducer,

    //Dashboard Boards Section
    dashboard_Boards: boardsSectionReducer,

    //Main Dashboard Section
    dashboard_Main: mainDashboardReducer,

    //Graph Color
    graphColors: graphsColorsReducer,

    //Virus total reducer
    vt: virusTotalReducer,

    resources: resourcesReducer,

    //Tenable
    tenable: tenableReducer,

    //QUALYS CVEs Dashboard
    qualys: qualysReducer,

    //Assets
    assets: assetsIndex,

    //Qualys Assets
    qualysAssets: qualysAssetsIndex,

    //enterprise
    enterprise: enterpriseReducers,

    //common site data
    commonData: commonDataReducer,

    //common site data
    blog: blogReducers,
});