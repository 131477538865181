/* eslint-disable import/no-anonymous-default-export */

const initialState = [];

export default (state = initialState, action) => {
    if (action.type === 'SET_BREACH_RULES') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};