//SET_MY_ENTERPRISE
import CyberSecurity from '../../apis/cyberSecurityData';

export const setEnterprise = (enterprise) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_ENTERPRISE',
    payload: enterprise
  })

}
