/* eslint-disable import/no-anonymous-default-export */

export default (state = 'Date', action) => { //'Date', 'Related Threats'

    if (action.type === 'SET_BREACHES_LIST_SORT_BY') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return 'Date'
    }


    return state
}