import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container, Form, Button, Tabs, Tab } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import animateScrollTo from 'animated-scroll-to';
import ReactPlayer from 'react-player';
import history from '../../../history';
import logo from '../../../images/trukno-logo-full.svg';
//comps
import LandingHeader from '../LandingPage/LandingHeader';
import VideoModal from './VideoModal';
import JoinUs from './JoinUs';
import ContactUs from './ContactUs';
import StartTrialForm from './StartTrialForm';
import RequestAccessForm from './RequestAccessForm';
//icons
import ReactTypingEffect from 'react-typing-effect';
import { Icon } from '@iconify/react';
import playCircleFill from '@iconify/icons-bi/play-circle-fill';
import playCircleFilled from '@iconify/icons-ant-design/play-circle-filled';
import circleChevronRightFill from '@iconify/icons-akar-icons/circle-chevron-right-fill';
import baselineArrowBack from '@iconify/icons-ic/baseline-arrow-back';
import baselineArrowForward from '@iconify/icons-ic/baseline-arrow-forward';
import twitterIcon from '@iconify/icons-bi/twitter';
import bxlLinkedin from '@iconify-icons/bx/bxl-linkedin';
import youtubeFill from '@iconify/icons-akar-icons/youtube-fill';
import slackIcon from '@iconify-icons/grommet-icons/slack';
import quoteOpen from '@iconify-icons/si-glyph/quote-open';
import emailaltIcon from '@iconify-icons/whh/emailalt';
import youtubeIcon from '@iconify/icons-cib/youtube';
import linkOut from '@iconify/icons-akar-icons/link-out';
import shieldCheck from '@iconify/icons-mdi/shield-check';

import playBtn from '../../../images/landing/content/play-gradient.svg';

import Slider from "react-slick";

import Lottie from 'react-lottie';
import mittreVideo from '../../../lottie/Trukno-2022_-Animation-3.json';
import RiskVideo from '../../../lottie/Trukno-2022_-Animation-2.json';
import uncoverVideo from '../../../lottie/Trukno-2022_-Animation-1.json';

//utils
import useOnScreen from '../../../utils/useOnScreen';


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}>
      <Icon icon={baselineArrowForward} color="#fff" fontSize="32px" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}>
      <Icon icon={baselineArrowBack} color="#fff" fontSize="32px" />
    </div>
  );
}

const slides = ["Main", "Breach", "TTP", "Malware", "Actor", "Vulnerability", "Assets", "IOC", "News Feed", "My board"];
const settings = {
  dots: false,
  speed: 500,
  lazyLoad: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '280px',
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        arrows: false,
        centerMode: false,
      }
    }]

};

const mittreDefaultOptions = {
  loop: true,
  autoplay: true,
  animationData: mittreVideo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const riskDefaultOptions = {
  loop: false,
  autoplay: false,
  animationData: RiskVideo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const uncoverDefaultOptions = {
  animationData: uncoverVideo,
};

const NewLandingPage = () => {
  //redux
  const currentUser = useSelector(state => state.currentUser);

  //local
  const sliderRef = useRef();
  const riskRef = useRef(null);
  const titleRef = useRef();

  const isRiskVideoVisible = useOnScreen(riskRef);
  const isTitleVisible = useOnScreen(riskRef);

  const [scroll, setScroll] = useState(false);
  const [selectedTab, setselectedTab] = useState('Main');
  const [showVideoModal, setShowVideoModal] = useState(false);


  const [muted, setMuted] = useState(true);
  const [isStopped, setIsStopped] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1000);
    });
  }, []);

  useEffect(() => {
    isRiskVideoVisible ? setIsStopped(false) : setIsStopped(true);
  }, [isRiskVideoVisible]);


  const onContactClick = () => {
    animateScrollTo(document.querySelector('#demo'));
  };

  return (
    <div className="landing">
      <div ref={titleRef} ></div>
      <LandingHeader onContactClick={onContactClick} isTitleVisible={isTitleVisible} />
      <main>

        <section id="hero-section" className="landing-hero-section">
          <div className="container">
            <div className="row">
              <Col md={6} xl={5} className="shadow-sm">
                <div className="text-left">
                  <h1 className="title-primary title-primary--light">Predict & Detect<span className="text-highliten"> </span><p className="text-highliten mb-0">Adversary Attack Behaviour</p></h1>
                  <h3 className="title-secondary"><span>In the MITRE ATT&CK framework</span></h3>
                  <ul>
                    <li><span style={{ width: '20px' }}><Icon icon={shieldCheck} style={{ fontSize: '20px' }} /></span>Latest Attack Sequences (Prevalence & Choke Points)</li>
                    <li><span style={{ width: '20px' }}><Icon icon={shieldCheck} style={{ fontSize: '20px' }} /></span>New Procedures (Actionability)</li>
                    <li><span style={{ width: '20px' }}><Icon icon={shieldCheck} style={{ fontSize: '20px' }} /></span>Emerging Techniques (Anticipate)</li>
                  </ul>

                  <div className="mt-4 pb-md-4 d-inline-flex flex-wrap">
                    <span className="btn btn-gradient" onClick={() => history.push('/demo')}>Request a demo</span>
                    {/* <span className="btn btn-solid-grey ml-3" onClick={() => setShowVideoModal(true)}>How iT Works</span> */}
                  </div>
                </div>

              </Col>

              <Col md={6} xl={7} className="mt-4 mt-md-0 pt-4 text-center shadow-lg" >
                {/* <img src={require('../../../images/landing/landing-video-poster.png')} className="img-fluid boards-section__image" alt="New Industry Member" /> */}
                {/* <ReactPlayer width="100%" height="100%" url={'https://www.youtube.com/watch?v=kviLv25PHBg&t=1s'} playsinline controls /> */}
                <img src={require('../../../images/landing/content/truckno-banner.png')} className="img-fluid" alt="Vulnerability Intelligence" />

              </Col>

            </div>
          </div>
        </section>

        <section className="features-section">
          <div className="container">

            <Row>
              <Col xs={12} md={4} className="mb-4">
                <div className="features-section__card">
                  <div className="features-section__icon">
                    <img src={require('../../../images/landing/content/reduce-breach-risk.png')} className="img-fluid" alt="Reduce Breach Risk" />

                  </div>
                  <h4 className="features-section__title">Reduce Breach Risk</h4>

                  <p className="features-section__content">Real-time actionable threat intelligence.</p>
                </div>
              </Col>

              <Col xs={12} md={4} className="mb-4">
                <div className="features-section__card">
                  <div className="features-section__icon">
                    <img src={require('../../../images/landing/content/save-time.png')} className="img-fluid" alt="Save Time" />

                  </div>
                  <h4 className="features-section__title">Save Time</h4>

                  <p className="features-section__content">Highly curated analysis of the active cyber attacks to save you time.</p>
                </div>
              </Col>

              <Col xs={12} md={4} className="mb-4">
                <div className="features-section__card">
                  <div className="features-section__icon">
                    <img src={require('../../../images/landing/content/improve-threat-hunting.png')} className="img-fluid" alt="Improve Threat Hunting" />

                  </div>
                  <h4 className="features-section__title">Improve Threat Hunting</h4>

                  <p className="features-section__content">Discover emerging threats that pose
                    the highest risk to your organization.</p>
                </div>
              </Col>

            </Row>

          </div>
        </section>

        <section className="section-dark overflow-hidden py-0">
          <Container>
            <Row className="align-items-center">
              <Col md={5} className="py-4">
                <h3 className="title-secondary mb-0">IMPROVE YOUR SECURITY POSTURE</h3>
                <h2 className="title-primary title-primary--light"><strong className="text-highliten">Uncover Emerging </strong>
                  Attack Techniques</h2>
                <p className="section-description mb-0">Keep up with how adversaries are bypassing
                  enterprise security controls based on the latest
                  cyber attack sequences in the wild.
                </p>
              </Col>

              <Col md={7}>
                <div className="blue-bg-shadow">

                  <div className="attack-techniques-video">
                    <Lottie
                      style={{ width: '100%' }}
                      options={uncoverDefaultOptions}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section-light-grey">
          <Container>
            <Row className="align-items-center">
              <Col md={7}>
                <h2 className="title-primary title-primary--dark"><strong className="text-highliten">Ultra Prioritization </strong>of <br />Assets & Vulnerability Risk</h2>
              </Col>

              <Col md={5}>
                <div className="what-matter-block__wrapper">
                  <div className="what-matter-block">
                    <h4 className="what-matter-block__title"><span className="text-highliten">Fix what matters most</span></h4>
                    <p className="what-matter-block__content">Real time insight into why? where? when? who? what? and how? your network vulnerabilites are being exploited in the wild.</p>
                  </div>
                </div>
              </Col>

              <Col md={12} className="pt-5 pt-md-5">
                <div ref={riskRef}>
                  <Lottie
                    options={riskDefaultOptions}
                    isStopped={isStopped}
                  />
                </div>
              </Col>

            </Row>
          </Container>
        </section>

        <section className="section-white py-5">
          <Container>
            <Row className="align-items-center position-relative">

              <Col sx={12} md={{ span: 6, offset: 6 }} className="mittre-video-wrapper">
                <div className="mitre-info-block">
                  <h2 className="title-primary title-primary--sm title-primary--dark pb-0 mb-0">IOC MITRE ATT&CK Sequence</h2>
                  <h3 className="title-secondary mb-3"><strong className="text-highliten">REDUCE THREAT INVESTIGATION TIME </strong></h3>
                  <p className="section-description section-description--dark"> Understand cyber attack sequences (at MITRE ‘Procedure’ level) associated with malicious IP addresses, file hashes, domains, malware, actors, etc.</p>
                </div>
              </Col>

              <Col md={12}>
                <div className="mittre-video">
                  <Lottie
                    style={{ width: '120%!important', margin: '0 auto!important' }}
                    options={mittreDefaultOptions}
                  />
                </div>
              </Col>



            </Row>
          </Container>
        </section>

        <section className="section-dark py-4 py-md-5">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Container>
                  <Row>
                    <Col md="12">
                      <div className="d-flex justify-content-between align-items-center py-3">
                        <h2 className="title-primary title-primary--light py-0 mb-0">Our Dashboards</h2>

                        <div>
                          <span className="d-inline-block p-2 pointer" onClick={() => sliderRef.current.slickPrev()}><Icon icon={baselineArrowBack} color="#fff" fontSize="28px" /></span>
                          <span className="d-inline-block p-2 pointer" onClick={() => sliderRef.current.slickNext()}><Icon icon={baselineArrowForward} color="#fff" fontSize="28px" /></span>
                        </div>
                      </div>

                      <Tabs
                        defaultActiveKey="Main"
                        activeKey={selectedTab}
                        // onSelect={(k) => setKey(k)}
                        className="slider-tabs d-none d-lg-flex"
                        onSelect={(tab) => {
                          sliderRef.current.slickGoTo(slides.indexOf(tab));
                          setselectedTab(tab);
                        }}
                      >
                        <Tab eventKey="Main" title="Main"></Tab>
                        <Tab eventKey="Breach" title="Breach"></Tab>
                        <Tab eventKey="TTP" title="TTP"></Tab>
                        <Tab eventKey="Malware" title="Malware"></Tab>
                        <Tab eventKey="Actor" title="Actor"></Tab>
                        <Tab eventKey="Vulnerability" title="Vulnerability"></Tab>
                        <Tab eventKey="Assets" title="Assets"></Tab>
                        <Tab eventKey="IOC" title="IOC"></Tab>
                        <Tab eventKey="News Feed" tabClassName="last-childs" title="News Feed"></Tab>
                        <Tab eventKey="My board" tabClassName="last-childs" title="My board"></Tab>
                      </Tabs>
                    </Col>
                  </Row>
                </Container>

                <div className="slider-box mt-4 mt-lg-0">
                  <Slider
                    ref={sliderRef}
                    {...settings}
                    afterChange={index => setselectedTab(slides[index])}
                  >
                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Keep up with the latest cyber threats attacking your networks, your industry/peers/vendors, etc.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/main-dashboard.png')} className="img-fluid" alt="main dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Highly curated analysis of the active cyber attack campaigns to save you time & improve threat hunting.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/breach-dashboard.png')} className="img-fluid" alt="breach dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Hunt Smarter not Harder. Understand MITRE TTPs (at a ‘Procedure’ level) used by adversaries in the latest cyber
                          attack campaigns so you can enhance your threat detection capabilities.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/ttp-dashboard.png')} className="img-fluid" alt="ttp dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Real-time snapshot of the how top malware campaigns are evolving in terms of attack sequences (MITRE
                          TTPs), vulnerability exploitation (CVEs), IOCs, etc.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/malware.png')} className="img-fluid" alt="assets dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Up to date actors profiles in terms of latest activity, attack sequences (MITRE TTPs), vulnerability
                          exploitation (CVEs), IOCs, etc.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/actor-dashboard.png')} className="img-fluid" alt="actor dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Riskiest vulnerabilities on your network based on CVE technical severity, active exploitations in the wild &
                          your assets impacted.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/vulnerability.png')} className="img-fluid" alt="assets dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Riskiest assets on your network based on asset importance & vulnerability intelligence.</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/assets.png')} className="img-fluid" alt="assets dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Real-time feed of Indicators of Compromise (IOC) used in the active malware/actor campaigns so you can
                          proactively block them.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/ioc-dashboard.png')} className="img-fluid" alt="ioc dashboard image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">300+ top cyber feeds aggregated in one place. 3,000+ articles per week. Easily filter breach & vulnerability
                          news articles. Customize your feed in 2 mins.​</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/news-feed.png')} className="img-fluid" alt="news-feed image" />
                    </div>

                    <div className="slide-wrapper">
                      <div className="slider-description">
                        <span className="slider-description__text">Take personal notes and add tags to keep track of important alerts & threats.</span>
                        {/* <a href="" className="learn-more"> Learn more <Icon icon={circleChevronRightFill}></Icon></a> */}
                      </div>
                      <img src={require('../../../images/landing/content/my-board.png')} className="img-fluid" alt="my board image" />
                    </div>

                  </Slider>
                </div>

                <Container>
                  <Row>
                    <Col md={12}>
                      <div className={scroll ? "api-integration-section-wrapper-animated" : "api-integration-section-wrapper"}>
                        <div className="api-integration-section">
                          <Row className="align-items-center">
                            <Col lg={7} className="p-4 p-lg-5 d-flex d-lg-block flex-column justify-content-center align-items-center">
                              <h2 className="title-primary title-primary--sm title-primary--pink pb-2">Seemless
                                <span className="text-highliten"> API intergration </span>
                                <br /> with your environment
                              </h2>
                              <ul className="api-integration-section__list">
                                <li className="api-integration-section__list-item"><span>Robust RESTful API</span></li>
                                <li className="api-integration-section__list-item"><span>Out of the Box Eco-System Integrations</span></li>
                              </ul>
                              <Button className="btn-gradient-red mt-4 width-auto" onClick={() => history.push('/demo')}><span>Get Demo <Icon icon={circleChevronRightFill} className="ml-2" /></span></Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section-white py-5">
          <Container>
            <Row className="align-items-center">

              <Col md={12} className="pt-5 position-relative">
                <h2 className="title-primary title-primary--sm title-primary--dark text-center">Thought’s from Cybersecurity Leaders</h2>

              </Col>

              <Col sm={4}>
                <div className="leader-card">
                  <div className="leader-card__body">
                    <img width="48" src={require('../../../images/landing/content/quote-icon.png')} className="img-fluid" alt="quote icon" />
                    <p className="leader-card__content">TruKno focus on attack behavior
                      analytics, not just IOCs offers
                      essential insights to my security
                      analysts.</p>
                  </div>

                  <div className="leader-card__footer">
                    <div className="d-block d-md-flex row align-items-center">
                      <div className="col-md-12">
                        <strong>James</strong>
                        <span>CISO</span>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>

              <Col sm={4}>
                <div className="leader-card">
                  <div className="leader-card__body">
                    <img width="48" src={require('../../../images/landing/content/quote-icon.png')} className="img-fluid" alt="quote icon" />
                    <p className="leader-card__content">Our threat hunting & detection
                      engineering team leverages
                      TruKno platform on a daily basis.</p>
                  </div>

                  <div className="leader-card__footer">
                    <div className="d-block d-md-flex row align-items-center">
                      <div className="col-md-12">
                        <strong>Greg</strong>
                        <span className="text-normal">Director, </span>
                        <span>Threat Intelligence/Detection</span>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>

              <Col sm={4}>
                <div className="leader-card">
                  <div className="leader-card__body">
                    <img width="48" src={require('../../../images/landing/content/quote-icon.png')} className="img-fluid" alt="quote icon" />
                    <p className="leader-card__content">TruKno dashboards are beautifully
                      designed & super easy to use to
                      keep up with the modern cyber
                      threat landscape.</p>
                  </div>

                  <div id="contact-us" className="leader-card__footer">
                    <div className="d-block d-md-flex row align-items-center">
                      <div className="col-md-12">
                        <strong>Tyler</strong>
                        <span>IT Director</span>
                      </div>
                    </div>
                  </div>

                </div>
              </Col>

            </Row>
          </Container>
        </section>

        <section className="section-dark get-demo">
          <Container>
            <Row className="align-items-center">

              <Col md={12} className="py-5 position-relative">
                <h2 className="title-primary title-primary--pink text-center">Get a <strong className="text-highliten">personalised demo</strong> <br />according to your team’s need</h2>
                <div className="text-center">
                  <Button className="btn-gradient-red" onClick={() => history.push('/demo')}><span>REQUEST DEMO</span></Button>
                </div>
              </Col>

            </Row>
          </Container>
        </section>

        <section className="section-white landing-page-form pb-5">
          <Container className="container-sm">
            <Col lg={12} className="pt-4 pt-md-5 position-relative">
              <h2 className="title-primary title-primary--sm title-primary--dark text-center pb-0">Contact US</h2>
              <h5><span className="px-3">Let’s get in touch.</span></h5>
              <ContactUs />
            </Col>
          </Container>
        </section>

      </main >
      <footer className="footer-section">
        <div className="container pb-4 pb-md-5">
          <Row>
            <Col lg={6} className="text-center pr-lg-5">
              <h2 className="title-primary title-primary--sm title-primary--light text-center pb-0">Threat Trend Newsletter</h2>
              <p className="text-grey text-center">We recap the latest threat landscape trends and send them directly to your inbox.</p>
              <span className="title-secondary">no spam - we promise</span>

              <div className="landing-page-form mt-4">
                {/* Join Us Form */}
                <JoinUs />
              </div>

            </Col>

            <Col lg={6} className="border-left  border-top-md pl-lg-5">
              <h2 className="title-primary title-primary--sm title-primary--light text-center pb-0">Follow us for regular critical updates</h2>
              <p className="text-grey text-center">Everything that’s going in cyber industry</p>
              <ul className="footer-socials">
                <li className="ml-0">
                  <a href="https://www.linkedin.com/company/trukno" rel="noreferrer" target="_blank">
                    <span className="footer-socials--share"><Icon icon={linkOut} style={{ color: 'rgba(255,255,255,.2)', fontSize: '24px' }} /></span>
                    <span style={{ display: 'flex', alignItems: 'center', height: '32px' }}><Icon icon={bxlLinkedin} style={{ color: '#fff', fontSize: '32px' }} /></span>
                    <span className="footer-socials--name">LinkedIn</span>
                  </a>
                </li>
                <li className="ml-0">
                  <a href="https://www.youtube.com/@trukno8119" rel="noreferrer" target="_blank">
                    <span className="footer-socials--share"><Icon icon={linkOut} style={{ color: 'rgba(255,255,255,.2)', fontSize: '24px' }} /></span>
                    <span style={{ display: 'block', alignItems: 'center', height: '32px' }}><Icon icon={youtubeFill} style={{ color: '#fff', fontSize: '32px' }} /></span>
                    <span className="footer-socials--name">Youtube</span>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/TruKno_cyber" rel="noreferrer" target="_blank">
                    <span className="footer-socials--share"><Icon icon={linkOut} style={{ color: 'rgba(255,255,255,.2)', fontSize: '24px' }} /></span>
                    <span style={{ display: 'block', alignItems: 'center', height: '32px' }}><Icon icon={twitterIcon} style={{ color: '#fff', fontSize: '22px' }} /></span>
                    <span className="footer-socials--name">Twitter</span></a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>

        <div className="footer-section__bottom py-3">
          <div className="container">
            <Row className="align-items-center">
              <Col xs={12} sm={4} className="d-flex d-md-block align-items-center justify-content-center justify-md-content-start mb-3 mb-sm-0">
                <img src={logo} alt="logo" className="img-fluid pointer" />
              </Col>

              <Col xs={12} sm={8} className="d-flex justify-content-center justify-content-md-end align-items-center">
                <ul className="footer-section__bottom-links">
                  <li>&copy; Trukno 2023</li>
                  <li><a href="https://www.trukno.com/terms.pdf" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                </ul>
              </Col>

            </Row>
          </div>
        </div>

      </footer>

      <VideoModal showModal={showVideoModal} setShowModal={setShowVideoModal} />
    </div >
  );
};

export default NewLandingPage;
