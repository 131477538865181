/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    withTTPs: true
};

export default (state = initialState, action) => {
    if (action.type === 'ADD_FILTER_SEARCH_PAGE') {
        return { ...state, [action.payload.key]: action.payload.value };
    }

    if (action.type === 'REMOVE_FILTER_SEARCH_PAGE') {
        return { ...state, [action.payload]: undefined };
    }

    if (['LOGOUT_USER', 'RESET_AUTOCOMPLETE_DATA', 'RESET_FILTERS_SEARCH_PAGE'].includes(action.type)) {
        return initialState;
    }

    return state;
};