import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import CyberSecurity from '../../../../apis/cyberSecurityData';
import { isEmail } from 'validator';

const EnterEmailForm = ({ setShowLoginModal, setActiveForm }) => {
  //comp local state
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);

  const onSendClick = async () => {
    if (!email || !isEmail(email)) return setErrors({ email: 'Please type a valid email address' });

    await CyberSecurity.post('/resetpassword', { email });

    setShowConfirm(true)
    setEmail('');
  }

  const onCloseClick = () => {
    setShowLoginModal(false);
    setTimeout(() => {
      setActiveForm('login');
    }, 500);
  }

  return (
    <div className="form-group-container--fixed-width pb-4">
      {!showConfirm && <p className="text-lg text-center text-semibold">Forgot your password?</p>}
      {!showConfirm && <div>
        <p className="text-md pb-3">Don’t worry, happens to the best of us.</p>
        <Form.Group className="pb-2">
          <Form.Control type="text" placeholder="Email address" value={email} onChange={e => setEmail(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') onSendClick() }} />
          {errors.email && <div className="error text-left" style={{ color: '#EA5454' }}>{errors.email}</div>}
        </Form.Group>
      </div>}

      {!showConfirm && <Button className="btn-custom w-100" onClick={onSendClick}>Email me a recovery link</Button>}
      {showConfirm && <p className="text-md">If account exists, an email will be sent with further instructions</p>}
      {errors.notMatch && <div className="error" style={{ color: '#EA5454' }}>{errors.notMatch}</div>}

      {showConfirm && <Button onClick={onCloseClick}>Close</Button>}
    </div>
  )
}

export default EnterEmailForm
