/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    breachRelated: {},
    ruleRelated: {},
};

export default (state = initialState, action) => {
    if (action.type === 'LOAD_DETECTIONS_BREACH_RELATED_FILTERS_DATA') {
        return { ...state, breachRelated: action.payload };
    }

    if (action.type === 'LOAD_DETECTIONS_RULE_RELATED_FILTERS_DATA') {
        return { ...state, ruleRelated: action.payload };
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};