/* eslint-disable import/no-anonymous-default-export */

export default (state = [], action) => {
    if (action.type === 'LOAD_BLOG_ITEMS') {
        return action.payload.items;
    }

    if (action.type === 'REMOVE_ITEM_FROM_BLOG') {
        return state.filter(el => el._id !== action.payload._id);
    }

    if (action.type === 'ADD_ITEM_TO_BLOG') {
        return [...state, action.payload];
    }

    if (action.type === 'LOGOUT_USER' || action.type === 'RESET_BLOG_ITEMS') {
        return [];
    }

    return state;
};