/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const initialState = [];

export default (state = initialState, action) => {

  if (action.type === 'SET_CVES_TENABLE') {
    if (action.payload?.length > 0) return action.payload;
    return [];
  }

  if (action.type === 'LOAD_MORE_CVES_TENABLE') {
    if (action.payload?.length > 0) return [...state, ...action.payload];
    return [];
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return initialState;
  }

  return state;
};