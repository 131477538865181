/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    actors: [],
    malwareTypes: [],
    malwareNames: [],
    domains: [],
    ips: [],
    hashesMd5: [],
    hashesSha1: [],
    hashesSha256: [],
    mitre: [],
    cves: [],
    ttps: [],
    tools: [],
    services: [],
    industries: [],
    technologies: [],
    categories: [],
    trendingStories: [],
    tags: [],
    sources: [],
    finishedItems: [],
    sortBy: 'latestBreach',
    time: 'All',
};

export default (state = initialState, action) => {
    if (action.type === 'SET_AUTOCOMPLETE_FIELD') {
        return { ...state, [action.payload.key]: action.payload.value, finishedItems: state.finishedItems.includes(action.payload.key) ? state.finishedItems : [...state.finishedItems, action.payload.key] };
    }

    if (action.type === 'SET_AUTOCOMPLETE_SORT_BY') {
        return { ...state, sortBy: action.payload };
    }

    if (action.type === 'SET_AUTOCOMPLETE_TIME_FILTER') {
        return { ...state, time: action.payload };
    }

    if (['LOGOUT_USER', 'RESET_AUTOCOMPLETE_DATA'].includes(action.type)) {
        return { ...initialState, sortBy: state.sortBy, time: state.time };
    }

    return state;
};