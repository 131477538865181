/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    basicInfo: true,
    ttps: true,
    procedures: true,
    iocs: true,
    breaches: true,
    filters: true
};

export default (state = initialState, action) => {
    if ([
        'SET_SEARCH_SELECTED_ITEM',
        'RESET_SEARCH_DATA',
        'ADD_FILTER_SEARCH_PAGE',
        'REMOVE_FILTER_SEARCH_PAGE',
        'RESET_FILTERS_SEARCH_PAGE',
    ].includes(action.type)) {
        return {
            basicInfo: true,
            ttps: true,
            procedures: true,
            iocs: true,
            breaches: true,
            filters: true
        };
    }

    if ([
        'SET_SEARCH_PAGE_WITH_TTPS',
    ].includes(action.type)) {
        return {
            // basicInfo: true,
            // ttps: true,
            // procedures: true,
            iocs: true,
            breaches: true,
            filters: true
        };
    }

    if ([
        'SET_SEARCH_RELATED_IOCS',
    ].includes(action.type)) {
        return { ...state, iocs: false };
    }

    if ([
        'SET_SEARCH_RELATED_PROCEDURES',
    ].includes(action.type)) {
        return { ...state, procedures: false };
    }

    if ([
        'SET_SEARCH_RELATED_BREACHES',
    ].includes(action.type)) {
        return { ...state, breaches: false };
    }

    if ([
        'SET_SEARCH_RELATED_TTPS',
    ].includes(action.type)) {
        return { ...state, ttps: false };
    }

    if (['LOGOUT_USER'].includes(action.type)) {
        return initialState;
    }

    return state;
};