import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
//Enterprise Reducers
import cardsReducer from './cardsReducer';
import groupCardsOrderReducer from './groupCardsOrderReducer';
import filtersOptionsReducer from './filtersOptionsReducer';
import activeGroupReducer from './activeGroupReducer';
import groupsReducer from './groupsReducer';


export default combineReducers({
    //Custom User Dashboard
    filtersOptions: filtersOptionsReducer,
    cards: cardsReducer,
    groupCardsOrder: groupCardsOrderReducer,
    activeGroup: activeGroupReducer,
    groups: groupsReducer,
    // items: cardsItemsReducer,
    // templateCards: templateCardsReducer,

});