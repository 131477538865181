/* eslint-disable import/no-anonymous-default-export */

export default (state = {}, action) => {

  if (action.type === 'SET_OPTIONS') {
    return action.payload
  }

  return state
}
