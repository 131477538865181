import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
//Enterprise Reducers
import blogItemsReducers from './blogItemsReducers';
import blogTotalItemsCountReducers from './blogTotalItemsCountReducers';


export default combineReducers({
    items: blogItemsReducers,
    totalCount: blogTotalItemsCountReducers,
});