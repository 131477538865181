/* eslint-disable import/no-anonymous-default-export */

const initialValues = {
  subscription: {},
  paymentMethods: [],
  defaultPaymentMethod: '',
  invoices: [],
}

export default (state = initialValues, action) => {
  if (action.type === 'UPDATE_SUBSCRIPTION') {
    return { ...state, subscription: action.payload };
  }

  if (action.type === 'UPDATE_PAYMENT_METHODS') {
    return { ...state, paymentMethods: action.payload };
  }

  if (action.type === 'UPDATE_DEFAULT_PAYMENT_METHODS') {
    return { ...state, defaultPaymentMethod: action.payload };
  }

  if (action.type === 'UPDATE_INVOICES') {
    return { ...state, invoices: action.payload };
  }

  if (action.type === 'LOGOUT_USER') {
    return initialValues;
  }

  return state;
}