/* eslint-disable import/no-anonymous-default-export */

export default (state = null, action) => {

    if (action.type === 'SET_THREATS_LIST_TOTAL_COUNT') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return null
    }

    return state
}