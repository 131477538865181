/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash';

const initialState = {}

export default (state = initialState, action) => {

  if (action.type === 'SET_TENABLE_COUNT') {
    return action.payload;
  }

  // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
  if (action.type === 'LOGOUT_USER') {
    return initialState
  }

  return state
}