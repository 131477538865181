/* eslint-disable import/no-anonymous-default-export */

const initialState = { mainCategory: 'Stories' }
export default (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_FILTER':
            let newFilter1 = { ...state };
            newFilter1[action.payload.key] = action.payload.value;
            return newFilter1;

        case 'REMOVE_FILTER':
            let newFilter2 = { ...state };
            delete newFilter2[action.payload.key]
            return newFilter2;
        case 'RESET_FILTER':
            let newFilter3 = { ...state };
            Object.keys(newFilter3).forEach(key => {
                if (key !== 'mainCategory') delete newFilter3[key]
            })
            return newFilter3;
        case 'LOGOUT_USER':
            return initialState;
        default:
            return state;
    }
}