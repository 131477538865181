/* eslint-disable import/no-anonymous-default-export */

import Cookies from 'js-cookie';

let initialState = {};

let currentUser = Cookies.get('currentUser');
console.log(currentUser);
if (currentUser) {
    let parsedUser = JSON.parse(currentUser);
    initialState = parsedUser;
}

console.log(initialState);

export default (state = initialState, action) => {
    if (action.type === 'EMAIL_SIGNUP' || action.type === 'EMAIL_LOGIN' || action.type === 'UPDATE_PROFILE' || action.type === 'LINKEDIN_LOGIN' || action.type === 'LINKEDIN_SIGNUP') {
        return action.payload.user;
    }

    if (action.type === "SET_CURRENT_USER") {
        return action.payload;
    }

    if (action.type === 'LOGOUT_USER') {
        return {};
    }

    return state;
};