/* eslint-disable import/no-anonymous-default-export */
const initialState = {
    breaches: [],
    totalCount: 0
};

export default (state = initialState, action) => {
    if (action.type === 'SET_SEARCH_TOP_BREACHES_HEADER') {
        return action.payload;
    }

    if (['LOGOUT_USER'].includes(action.type)) {
        return initialState;
    }

    return state;
};