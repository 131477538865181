/* eslint-disable import/no-anonymous-default-export */

const initialState = {
    activeEDR: '', // defender-edr or insights-idr
    activeSection: 'breaches', // rules | breaches
    loading: false,
    onlyDetected: false,
    results: [],
    page: 1,
    sortBy: 'breachDate', // breachDate or events
    sortOrder: -1,
    info: {},
};

export default (state = initialState, action) => {
    if (action.type === 'SET_BACKGROUND_SCAN_ACTIVE_EDR') {
        return { ...state, results: [], activeEDR: action.payload, loading: true, page: 1 };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_ACTIVE_SECTION') {

        let activeSection = action.payload; // rules | breaches
        let sortBy = 'breachDate';
        if (activeSection === 'rules') sortBy = 'breachCount';

        return { ...state, results: [], activeSection: action.payload, loading: true, page: 1, sortBy, sortOrder: -1 };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_LOADING') {
        return { ...state, loading: action.payload };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_INFO') {
        return { ...state, info: action.payload };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_RESULTS') {
        const results = state.page === 1 ? action.payload : [...state.results, ...action.payload];
        return { ...state, results, loading: false };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_PAGE') {
        return { ...state, page: action.payload };
    }

    if (action.type === 'INCREMENT_BACKGROUND_SCAN_PAGE') {
        return { ...state, page: state.page + 1 };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_SORT_BY') {
        const order = state.sortBy === action.payload ? state.sortOrder * -1 : -1;
        return { ...state, results: [], sortBy: action.payload, sortOrder: order, page: 1, loading: true };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_SORT_ORDER') {
        return { ...state, results: [], sortBy: action.payload, page: 1, loading: true };
    }

    if (action.type === 'SET_BACKGROUND_SCAN_ONLY_DETECTED') {
        return { ...state, results: [], onlyDetected: action.payload, page: 1, loading: true };
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return initialState;
    }

    return state;
};