import _ from 'lodash';

export default (state = {}, action) => {
    switch (action.type) {

        case 'FETCH_SPONSORED_ITEMS':
            //'FETCH_SPONSORED_ITEMS' actions returns an array, it will be converted to object mapped to id for easy updating and working with
            // return _.mapKeys(action.payload.items, '_id');
            return action.payload;

        default:
            return state;
    }
}