/* eslint-disable import/no-anonymous-default-export */

export default (state = 'Top Websites', action) => {
    if (action.type === 'UPDATE_TOP_FILTERS') {
        return action.payload.activeTopFilter;
    }

    if (action.type === 'UPDATE_ACTIVE_TOP_FILTERS') {
        return action.payload;
    }

    // -----------------------------------------  Reset the state when the user logs out ------------------------------------------
    if (action.type === 'LOGOUT_USER') {
        return 'Top Websites'
    }

    return state
}